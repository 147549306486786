import React from 'react';
import Avatar from 'react-avatar';

const UserAvatar = ({ firstName, lastName , email}) => {

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const randomColor = getRandomColor();

  const avatarText = `${email}`;
  return (
    <Avatar
      name={avatarText} //avatarText
      round // To display a circular avatar
      size="30" // Set the desired size in pixels
    />
  );
};

export default UserAvatar;
