import React from "react";
import { Button } from "react-bootstrap";
import { Users } from "phosphor-react";


export default function ActionBtn({title , Icon , onClick, children}) {
  return (
    <Button onClick={onClick}
    variant="secondary"
    className="action-btn m-2 d-flex justify-content-between align-items-center"
  >
    {Icon && <Icon size={24}/>}  
    <span>{title}</span>
    {children}
  </Button>
  );
}


