import React, { useState, useEffect } from "react";
import apiInstance from "../../api";
import { User, UserSwitch } from "phosphor-react";
import MakeUserAdmin from "../../components/super_admin/MakeUserAdmin";
import { Table, Form, Pagination, Button, Modal } from "react-bootstrap"; // Import Modal and Button

const Users_sa = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [typingTimeout, setTypingTimeout] = useState(null); // To hold the timeout

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout); // Clear previous timeout
    }

    const newTypingTimeout = setTimeout(() => {
      setSearch(search.trim());
      fetchUsers();
    }, 800);

    setTypingTimeout(newTypingTimeout); // Set the new timeout
  }, [currentPage, search]);

  const fetchUsers = async () => {
    try {
      const response = await apiInstance.get("/users", {
        params: { page: currentPage, search },
      });
      setUsers(response.data.data);
      setTotalPages(response.data.meta.last_page);
      //   console.log(users);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase()) ||
      user.created_at.includes(search)
  );

  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [newUserData, setNewUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setNewUserData({
      name: "",
      email: "",
      password: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddUser = async () => {
    try {
      const userDataWithConfirmation = {
        ...newUserData,
        c_password: newUserData.password,
      };

      await apiInstance.post("/register", userDataWithConfirmation); // Use your registration API endpoint
      // Refresh the user list or update the data as needed
      fetchUsers();
      handleCloseModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      
      <div>
      <Button variant="primary" onClick={handleShowModal}>
        Add New User
      </Button>

      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
          />
        </Form.Group>
      </Form>
      </div>

      <Table hover>
        <thead>
          <tr>
            <th>id</th>
            <th>Username</th>
            <th>Email</th>
            <th>Avatar</th>
            <th>is super admin</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                <img
                  src={user.avatar}
                  alt={`Avatar for ${user.name}`}
                  width="40"
                />
              </td>
              {/* <td>{user.is_admin?<UserSwitch className='text-success' size={24}/>:<User  size={24}/>  }</td> */}
              <td>
                <MakeUserAdmin userId={user.id} userType={user.is_admin} />
              </td>
              <td>{user.created_at}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination>
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
      </Pagination>

      {/* Add button to open modal */}

      {/* Modal for adding a new user */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newUserData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUserData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={newUserData.password}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users_sa;
