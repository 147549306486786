import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import apiInstance from "../../api";
import "./UsersTasks.css";
import default_img from '../../assets/img.webp'


const UsersTasks = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("id"); // Default sorting by id

  useEffect(() => {
    fetchUsersWithTotalCards();
  }, [page, searchTerm, sortBy]);

  const fetchUsersWithTotalCards = async () => {
    try {
      const response = await apiInstance.get("/getUsersWithTotalCards", {
        params: {
          page,
          search: searchTerm,
          sortBy,
        },
      });

      if (page === 1) {
        setUsers(response.data.data);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...response.data.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Container>
      <h2>Users with Total Cards</h2>
      <Row>
        <Form.Group className="col-9">
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="col-3">
          <Form.Control
            as="select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="id">Sort by ID</option>
            <option value="name">Sort by Name</option>
            <option value="email">Sort by Email</option>
            <option value="cards_count">Sort by Total Card Count</option>
          </Form.Control>
        </Form.Group>
      </Row>
      <Row>
        {users.map((user) => (
          <Col key={user.id} md={3} className="">
            <Card className="user-card">
              <Card.Body>
                <div className="user-id">{user.id}</div>
                <Card.Title>{user.name.substr(0, 15)}</Card.Title>
                <div className="email">
                  <i>{user.email.substr(0, 15)}</i>
                </div>
                <Card.Text>Total Cards: {user.cards_count}</Card.Text>
                <Image
                  className="user-img"
                  src={user.avatar?user.avatar:default_img}
                  alt={user.name.substr(0, 15)}
                />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Button className="mt-3" onClick={handleLoadMore}>
        Load More
      </Button>
    </Container>
  );
};

export default UsersTasks;
