import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditableText.css";
import SaveCancelButton from "./SaveCancelButton";
import { apiInstance } from "../../services/apiServices";
import "quill-mention";


const CommentWithMention = ({
  title,
  apiMethod,
  apiParams,
  type,
  className,
}) => {
  const [editing, setEditing] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);

  const quillRef = useRef(null);

  const handleTitleChange = (e) => {
    setUpdatedTitle(e);
  };
  const isEditable = true;
  const handleSave = () => {
    if (updatedTitle !== title) {
      setEditing(false);
      apiMethod(apiParams, updatedTitle);
      setUpdatedTitle("");
    } else {
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    setUpdatedTitle(title);
  };

  useEffect(() => {
    if (editing && quillRef.current) {
      quillRef.current.focus();
    }
  }, [editing]);

  const fetchUserMentions = async (searchTerm) => {
    const response = await apiInstance(`/user-suggestions?searchTerm=${searchTerm}`);
    const data = await response.json();
    return data.users; // Adjust the response structure as needed
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, "bold", "italic", "underline", "strike"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
    mentions: {
      allowedChars: /^[A-Za-z\s]*$/,
      trigger: "@",
      dataAttributes: ["id", "name", "email", "avatar"],
      renderItem: (item) => {
        return (
          <div className="mention-suggestion">
            <img src={item.avatar} alt={item.name} />
            <div>
              <strong>{item.name}</strong>
              <p>{item.email}</p>
            </div>
          </div>
        );
      },
      source: (searchTerm, renderList) => {
        // Use the defined fetchUserMentions function to fetch user mentions
        fetchUserMentions(searchTerm)
          .then((users) => {
            renderList(users);
          })
          .catch((error) => {
            console.error("Error fetching mentions:", error);
          });
      },
    },
  };

  return (
    <div>
      {editing ? (
        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={updatedTitle}
          onChange={handleTitleChange}
          modules={modules}
          placeholder="Type @ to mention users"
        />
      ) : (
        <div onClick={() => isEditable && setEditing(true)} className={className}>
          <div
            className="div-editable"
            dangerouslySetInnerHTML={{ __html: updatedTitle }}
          />
        </div>
      )}
      {editing && (
        <SaveCancelButton onSave={handleSave} onCancel={handleCancel} />
      )}
    </div>
  );
};

export default CommentWithMention;
