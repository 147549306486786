import {  Swap } from "phosphor-react";
import React, { useState, useEffect, useRef } from "react";
 import './StatusSelector.css';


const StatusSelector = ({ board, handleConfirmChangeStatus, space_status }) => {
  const [showOptions, setShowOptions] = useState(false);
  const statusSelectorRef = useRef(null);

  const handleStatusChange = (newStatus) => {
    handleConfirmChangeStatus(board.id, newStatus);
    setShowOptions(false); // Close the dropdown after selecting an option
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusSelectorRef.current && !statusSelectorRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="status-select" ref={statusSelectorRef}>
      <Swap
      className="swap-icon"
        onClick={() => setShowOptions(!showOptions)}
        size={24}
      />
      {showOptions && (
        <div className="status-options">
          <select
            value={board.status}
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            {space_status.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default StatusSelector;
