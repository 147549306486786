import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { createList, fetchList } from "../../slices/listSlice";
import { useParams } from "react-router-dom";

import "./AddList.css";
import SaveCancelButton from "../common/SaveCancelButton";

const AddList = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("Add another list");
  const [title, setTitle] = useState("");

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  let { boardId } = useParams();
  let board_id = boardId;
  const newList = { title, board_id };

  function handleAddList() {
    setTitle("");
    let res = dispatch(createList(newList));
    dispatch(fetchList(boardId));
    setIsEditing(false);
    // console.log("added", res);
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const HandleClose = () => {
    setIsEditing(false);
  };
  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddList();
    }
  };

  return (
    <div>
      {isEditing ? (
        <div className="add_list">
          <input
            autoFocus
            type="text"
            value={title}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleInputKeyPress} // Trigger when Enter key is pressed
          />
          <SaveCancelButton onSave={handleAddList} onCancel={HandleClose} />
        </div>
      ) : (
        <div onClick={handleEditClick} className="add-card">
          {text}
        </div>
      )}
    </div>
  );
};

export default AddList;
