import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSpaces, fetchWorkspaces } from "../slices/workspaceSlice";
import { Row, Col, Form, FormControl, Button, Card } from "react-bootstrap";
import { UserPlus, Pencil, PlusCircle } from "phosphor-react";
import BoardItem from "../components/Board/BoardItem";
import "./workspace.css";
import SpaceItem from "../components/Space/SpaceItem";
import CreateSpaceModal from "../components/Space/CreateSpaceModal";
import Breadcrumb from "../common/Breadcrumb";
import EditableText from "../components/common/EditableText";
import { updateWorkspaceById } from "../services/apiServices";
import EditablePencil from "../components/common/EditablePencil";

function Workspace() {
  // console.log(isDebugging? "workspace page":'') //debugging
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const { id } = useParams();
  let workspaceId = parseInt(id);
  // alert(typeof workspaceId)
  //TODOS when update page
  if (!workspaceId) {
    const currentURL = window.location.href;
    const idPattern = /\/w\/(\d+)/;
    const match = idPattern.exec(currentURL);
    workspaceId = match && match[1];
    workspaceId = workspaceId;
  }

  const state = useSelector((state) => state.auth);
  // console.log(isDebugging ? "workspace_id= " + workspaceId : ""); //debugging
  const spaces = useSelector((state) => state.workspaces.spaces);
  const workspaces = useSelector((state) => state.workspaces.data);

  // console.log("spaces", spaces);

  let workspace_ = workspaces.find((item) => item.id === workspaceId);
  console.log(state);

  const breadcrumbs = [
    { label: "Workspaces", link: "/w" },
    { label: workspace_?.name, link: `/w/${workspaceId}` },
  ];

  const updateTitle = (params, updateTitle) => {
    updateWorkspaceById(workspaceId, { name: updateTitle });
    dispatch(fetchWorkspaces());
    // workspace_ = workspaces.find((item) => item.id === workspaceId);
  };

  useEffect(() => {
    dispatch(fetchSpaces(workspaceId));
  }, [dispatch, workspaceId]);

  //  console.log(isDebugging ? "boards: " + boards : ""); //debugging

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container">
      <div className="header_breadcrumb">
        <Breadcrumb items={breadcrumbs} />
        <Button
          variant="success"
          className="mr-2"
          onClick={() => setShowModal(true)}
        >
          <PlusCircle size={22} />
        </Button>
      </div>

      <div className="row text-center ">
        <div className="col-sm-6">
          {workspace_ && (
            <div className="d-flex justify-between">
              <EditablePencil
                title={workspace_?.name}
                apiMethod={updateTitle}
                apiParams={workspace_?.id}
                className="workspace_edit_name"
                canEdit={workspace_?.user_id == state.user.user_id}
              />
            </div>
          )}
        </div>
      </div>

      <hr />

      <div className="row">
        <h3>Spaces</h3>
      </div>

      <Row className="m-3">
        <Col sm={2}>
          <Form.Group controlId="workspaceType">
            <Form.Label>sort by</Form.Label>
            <Form.Control
              as="select"
              value=""
              onChange={(e) => alert(e.target.value)}
            >
              <option value="team">Team</option>
              <option value="personal">Personal</option>
              <option value="project">Project</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group controlId="workspaceType">
            <Form.Label>filter by</Form.Label>
            <Form.Control
              as="select"
              value=""
              onChange={(e) => alert(e.target.value)}
            >
              <option value="team">Team</option>
              <option value="personal">Personal</option>
              <option value="project">Project</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={2} className="ml-auto">
          <Form.Label>Search</Form.Label>
          <FormControl type="text" placeholder="Search" />
        </Col>
      </Row>

      <Row className="ml-auto">
        <div
          className="col-sm-3  board-card text-center my-2 create_space"
          onClick={handleOpenModal}
        >
          <span>create new space</span>
        </div>

        {spaces &&
          spaces.map((item) => (
            <SpaceItem
              onClick={() => navigate(`${item.id}`)}
              key={item.id}
              id={item.id}
              spaceName={item.title}
              desc = {item.description}
            />
          ))}
      </Row>

      <CreateSpaceModal
        workspaceId={workspaceId}
        show={showModal}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default Workspace;
