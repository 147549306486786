import { InputGroup, FormControl, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { addCover } from "../../services/apiServices";
import { X } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardById } from "../../slices/cardSlice";
import { fetchList } from "../../slices/listSlice";
import { useParams } from "react-router-dom";
import { SwatchesPicker } from "react-color";

function AddCover({ id, list_id }) {
  const [color, setColor] = useState("#ccc");
  const [name, setName] = useState("");
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const lists = useSelector((state) => state.lists.data);
  const { boardId } = useParams();

  const handleAdd = () => {
    // addLabel(id, name, color);
    addCover(id, color);
    try {
      setColor("");
      setShow(false);

      dispatch(fetchCardById(id));
      dispatch(fetchList(boardId));
    } catch (error) {
      console.error("Error adding label:", error);
      // Handle errors...
    }
  };

  return (
    show && (
      <div
        className="row"
        style={{
          position: "absolute",
          top: "0px",
          left: 0,
          width: "fit-content",
          height: "fit-content",
          background: "#ccc",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 999,
          visibility: show ? "" : "hidden",
        }}
      >
        <div className="col-12 m-2">
          <h3>Cover of Card</h3>
          <SwatchesPicker color={color} onChange={handleChange} />

          <p>Selected Color: {color}</p>
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleAdd}>
              Add Cover
            </Button>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default AddCover;
