import React, { useState, useEffect } from "react";
import { apiInstance } from "../services/apiServices";
import "./LinkList.css";

function LinkList() {
  const [showModal, setShowModal] = useState(false);
  const [editLink, setEditLink] = useState(null);
  const [links, setLinks] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchLinks();
  }, [searchType, searchTitle, currentPage]);

  const fetchLinks = async () => {
    try {
      const response = await apiInstance.get("/links", {
        params: { type: searchType, title: searchTitle, page: currentPage },
      });
      setLinks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchLinks();
  };

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  const handleCopyToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      alert("URL copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
  };

  const handleCreateLink = async (linkData) => {
    try {
      const response = await apiInstance.post("/links", linkData);
      setLinks([...links, response.data.link]);
      alert("Link created successfully");
      setShowModal(false);
    } catch (error) {
      console.error("Failed to create link: ", error);
    }
  };

  const handleEditLink = (link) => {
    setEditLink(link);
    setShowModal(true);
  };

  const handleUpdateLink = async (linkData) => {
    try {
      const response = await apiInstance.put(`/links/${editLink.id}`, linkData);
      const updatedLinks = links.map((link) =>
        link.id === response.data.link.id ? response.data.link : link
      );
      setLinks(updatedLinks);
      alert("Link updated successfully");
      setShowModal(false);
    } catch (error) {
      console.error("Failed to update link: ", error);
    }
  };

  const handleDeleteLink = async (linkId) => {
    try {
      await apiInstance.delete(`/links/${linkId}`);
      setLinks(links.filter((link) => link.id !== linkId));
      alert("Link deleted successfully");
    } catch (error) {
      console.error("Failed to delete link: ", error);
    }
  };

  return (
    <div>
      <h1>Links</h1>
      <div>
        <button onClick={() => setShowModal(true)}>Add Link</button>
      </div>

      {showModal && (
        <div className="modal">
          <h2>{editLink ? "Edit Link" : "Add Link"}</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (editLink) {
                handleUpdateLink(editLink);
              } else {
                handleCreateLink(editLink);
              }
            }}
          >
            {/* Add your form fields here for adding/editing links */}
            <input
              type="text"
              placeholder="Type"
              value={editLink ? editLink.type : ""}
              onChange={(e) =>
                setEditLink({ ...editLink, type: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Title"
              value={editLink ? editLink.title : ""}
              onChange={(e) =>
                setEditLink({ ...editLink, title: e.target.value })
              }
              required
            />
            <input
              type="url"
              placeholder="URL"
              value={editLink ? editLink.url : ""}
              onChange={(e) =>
                setEditLink({ ...editLink, url: e.target.value })
              }
              required
            />
            <button type="submit">
              {editLink ? "Update" : "Create"}
            </button>
          </form>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      )}

      <div className="link_cards">
        {links.map((link) => (
          <div className="link_card" key={link.id}>
            <h3>{link.title}</h3>
            <p>{link.url}</p>
            <button className="btn_1" onClick={() => handleOpenUrl(link.url)}>
              Open URL
            </button>
            <button
              className="btn_1"
              onClick={() => handleCopyToClipboard(link.url)}
            >
              Copy URL
            </button>
            <button className="btn_1" onClick={() => handleEditLink(link)}>
              Edit
            </button>
            <button
              className="btn_1"
              onClick={() => handleDeleteLink(link.id)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <div>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={links.length < 10}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default LinkList;
