// api.js

import axios from "axios";
import { API_BASE_URL } from "./config";

//bug 001
const user = JSON.parse(localStorage.getItem('user'));
const token = user && user.token;

 const  apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : '', // Add the Bearer token to headers
  },
});

export default apiInstance;
