import React, { useState, useEffect, useRef } from "react";
import { apiInstance } from "../services/apiServices";
import "./Notifications.css";
import { Spinner } from "react-bootstrap"; // Import Bootstrap Spinner component

const Notifications = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // State variable for loading indicator
  const loader = useRef(null);

  const fetchMoreData = async () => {
    try {
      setIsLoading(true); // Show loading indicator
      const response = await apiInstance.get(`/myNotifications?page=${page}`);
      const newNotifications = response.data.data;

      if (newNotifications.length === 0) {
        // No more data to load, so stop fetching
        return;
      }

      const newNotificationsArray = Object.values(newNotifications);
      console.log(newNotificationsArray);
      setData((prevData) => [...prevData, ...newNotificationsArray]);
      setPage(page + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setIsLoading(false); // Hide loading indicator when done
    }
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      fetchMoreData();
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  return (
    <div className="notifications-container">
      <h1>Notifications</h1>
      <ul className="notifications-list" style={{ minHeight: "80vh" }}>
        {data.map((notification) => (
          <li key={notification.id} className="notification-item">
            <div className="notification-text">
              <span className="user-badge">User: {notification.user_id}</span>{" "}
              <span className="event-badge">{notification.event_type}</span>{" "}
              <span className="task-badge">{notification.event_data} </span>{" "}
              <span className="card-badge">{" "}
                on Task {notification.card_id}{" "}
              </span>
            </div>
            <div className="notification-timestamp">
              {notification.created_at}
            </div>
          </li>
        ))}
        <div ref={loader} />
        {isLoading && ( // Show loading indicator when isLoading is true
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Notifications;
