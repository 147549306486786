import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearEvents, fetchEvents } from "../../slices/eventSlice";
import "./EventList.css";

const EventList = ({ cardId }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1); // Current page for pagination
  const eventsPerPage = 5; // Number of events to display per page

 

  useEffect(() => {
    // dispatch(clearEvents());
    dispatch(fetchEvents({ cardId, page, eventsPerPage }));
  }, [page]);

  const loadMore = () => {
    setPage(page + 1); // Increment page to fetch more events
  };

 
  const events = useSelector((state) => {
    // Use a Set to store unique event IDs
    const uniqueEventIds = new Set();

    // Filter out duplicate events by checking their IDs
    return state.events.events.filter((event) => {
      if (!uniqueEventIds.has(event.id)) {
        uniqueEventIds.add(event.id);
        return true;
      }
      return false;
    });
  });

  const status = useSelector((state) => state.events.status);
  const error = useSelector((state) => state.events.error);

  // console.log("events", events);
  return (
    <div className="card-events-list">
      <ul>
        {events &&
          events.map((event) => (
            <li key={event.id}>
              <span className="user-event">{event.user_id} _ </span>
              <span className="user-type">{event.event_type} _ </span>
              <span className="user-data">{event.event_data} _ </span>
              <span className="user-date">{event.created_at}</span>
            </li>
          ))}
      </ul>
      {status === "loading" && <p>Loading...</p>}
      {status === "error" && <p>Error: {error}</p>}
      {/* {events.length +" >= "+page * eventsPerPage} for debugging */}
      {status !== "loading" && events.length >= page * eventsPerPage && (
        <button onClick={loadMore}>Load More</button>
      )}
    </div>
  );
};

export default EventList;
