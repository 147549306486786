// Generate a random color in hex format
export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  
  // Get the initials from a name (assumes name is a full name)
  export const getInitials = (name) => {
    const nameParts = name.split(' ');
    if (nameParts.length >= 2) {
      return nameParts[0][0] + nameParts[1][0];
    } else if (nameParts.length === 1) {
      return nameParts[0].substring(0, 2);
    }
    return '';
  };

  export const  generateColorFromId = (id)=> {
    // Convert the ID to a hue value between 0 and 360
    const hue = (id * 37) % 360;
  
    // Generate an HSL color string
    const color = `hsl(${hue}, 70%, 50%)`;
  
    return color;
  }
  
  