import React from "react";
import { FloppyDisk, X } from "phosphor-react";

const SaveCancelButton = ({ onSave, onCancel }) => {
  return (
    <div className="save-cancel-buttons">
      <button className="nice-btn btn-save" onClick={onSave}>
        <FloppyDisk size={24} />
      </button>
      <button className="nice-btn btn-cancel" onClick={onCancel}>
        <X size={24} />
      </button>
    </div>
  );
};

export default SaveCancelButton;
