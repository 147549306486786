import React, { useState, useEffect } from "react";
import { apiInstance } from "../../services/apiServices";
import "./TaskPermissions.css";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import toast from "react-hot-toast";
import { Tab, Nav } from "react-bootstrap";
import TaskPermissions_remove from "./TaskPermissions_remove";

function WorkspaceComponent() {
  const [users, setUsers] = useState([]);
  const [selectedusers, setSelectedusers] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [boards, setBoards] = useState({});
  const [tasks, setTasks] = useState({});

  const [activeTab, setActiveTab] = useState("tab1");
  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };



  const [selectWorkspaceLevel, setSelectWorkspaceLevel] = useState(false);
  const [selectSpaceLevel, setSelectSpaceLevel] = useState(false);
  const [selectBoardLevel, setSelectBoardLevel] = useState(false);
  const clearTaskSelection = (task_id) => {
    const updatedPermission = selectedPermission.filter(
      (perm) => perm.id !== task_id
    );
    setSelectedPermission(updatedPermission);
  };

  const handleSelectOnWorkspaceLevel = () => {
    setSelectWorkspaceLevel((prev) => !prev);
  };
  const handleSelectOnSpaceLevel = () => {
    setSelectedBoards([]);
    setSelectSpaceLevel((prev) => !prev);
  };

  const handleSelectOnBoardLevel = () => {
    setSelectBoardLevel((prev) => !prev);
  };

  useEffect(() => {
    apiInstance.get("/getAllWorkspaces").then((response) => {
      setWorkspaces(response.data);
    });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await apiInstance.get("/getUsersEmails"); // Replace with your API endpoint
      const userEmails = response.data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      setUsers(userEmails);
      // console.log("users",users);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUsersChange = (selectedOptions) => {
    setSelectedusers(selectedOptions);
  };

  const handleWorkspaceSelect = (workspace) => {
    setSelectedWorkspace(workspace);
    setSelectedSpaces([]); // Clear selected spaces
    setSelectedBoards({}); // Clear selected boards
    setSelectedTasks({}); // Clear selected tasks

    if (!selectWorkspaceLevel) {
      apiInstance.get(`/workspaces/${workspace}/spaces`).then((response) => {
        setSpaces(response.data.data);
      });
    }
  };

  const handleSpaceSelect = (spaceId) => {
    setSelectedBoards({}); // Clear selected boards
    setSelectedTasks({}); // Clear selected tasks

    if (selectedSpaces.includes(spaceId)) {
      setSelectedSpaces((prevSelected) =>
        prevSelected.filter((id) => id !== spaceId)
      );
    } else {
      setSelectedSpaces((prevSelected) => [...prevSelected, spaceId]);
      apiInstance.get(`/spaces/${spaceId}/boards2`).then((response) => {
        const newBoards = { ...boards };
        response.data.forEach((board) => {
          newBoards[board.id] = { ...board, tasks: [] };
        });
        setBoards(newBoards);
      });
    }
  };

  const handleBoardSelect = (boardId, spaceId) => {
    if (selectedBoards[boardId]) {
      setSelectedBoards((prevSelected) => {
        const newSelectedBoards = { ...prevSelected };
        delete newSelectedBoards[boardId];
        return newSelectedBoards;
      });
    } else {
      setSelectedBoards((prevSelected) => ({
        ...prevSelected,
        [boardId]: true,
      }));
      apiInstance.get(`/board/${boardId}/tasks`).then((response) => {
        const newTasks = { ...tasks };
        newTasks[boardId] = response.data;
        setTasks(newTasks);
      });
    }
  };

  const [selectedTasks_, setSelectedTasks_] = useState([]);

  const handleTaskSelect = (taskId, boardId) => {
    setSelectedTasks((prevSelected) => {
      const newSelectedTasks = { ...prevSelected };

      if (
        newSelectedTasks[boardId] &&
        newSelectedTasks[boardId].includes(taskId)
      ) {
        // Deselect the task
        newSelectedTasks[boardId] = newSelectedTasks[boardId].filter(
          (id) => id !== taskId
        );
      } else {
        // Select the task
        newSelectedTasks[boardId] = newSelectedTasks[boardId]
          ? [...newSelectedTasks[boardId], taskId]
          : [taskId];
      }

      // Flatten the selected task IDs into a single array
      const selectedTaskIds = Object.values(newSelectedTasks).flatMap(
        (taskIds) => taskIds
      );
      setSelectedTasks_(selectedTaskIds);

      return newSelectedTasks;
    });
  };

  const [selectedPermission, setSelectedPermission] = useState([]);
  const handleRadioChange = (task_id, val) => {
    const updatedPermission = [...selectedPermission];
    const existingPermissionIndex = updatedPermission.findIndex(
      (perm) => perm.id === task_id
    );

    if (existingPermissionIndex !== -1) {
      // Update an existing object
      updatedPermission[existingPermissionIndex] = { id: task_id, type: val };
    } else {
      // Add a new object
      updatedPermission.push({ id: task_id, type: val });
    }
    // console.log(updatedPermission);
    setSelectedPermission(updatedPermission);
  };

  const handleSubmit = () => {
    if (selectedusers.length === 0) {
      toast.error("You must choose some users to invite", {
        duration: 2000,
        position: "top-center",
      });
    }
    // if (selectedTasks_.length === 0) {
    //   toast.error("You must choose some tasks first", {
    //     duration: 2000,
    //     position: "top-center",
    //   });
    // }

    const dataToSend = {
      workspace: selectedWorkspace,
      spaces: selectedSpaces,
      boards: Object.keys(selectedBoards),
      selectedusers,
      // selectedTasks_,
      selectedPermission,
    };

    console.log(dataToSend);

    apiInstance
      .post("/inviteUsersByAdminToCards", dataToSend)
      .then((response) => {
        // console.log("API Response:", response.data);
        if (response.data.status === true) {
          toast.success("users invited successfully !", {
            duration: 2000,
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        toast.error("some errors happened during inviting operation", {
          duration: 2000,
          position: "top-center",
        });
      });
  };

  //Select All Tasks Functionality
  const [selectAllTasks, setSelectAllTasks] = useState(false);
  const handleSelectAllTasks = () => {
    const allTaskIds = Object.values(tasks).flatMap((boardTasks) =>
      boardTasks.map((task) => task.id)
    );

    if (selectAllTasks) {
      // Deselect all tasks
      const newSelectedTasks = { ...selectedTasks };
      Object.keys(newSelectedTasks).forEach((boardId) => {
        newSelectedTasks[boardId] = [];
      });
      setSelectedTasks(newSelectedTasks);
      setSelectedTasks_([]);
    } else {
      // Select all tasks
      const newSelectedTasks = {};
      Object.keys(boards).forEach((boardId) => {
        newSelectedTasks[boardId] = [...allTaskIds];
      });
      setSelectedTasks(newSelectedTasks);
      setSelectedTasks_(allTaskIds);
    }

    // Toggle the "Select All" checkbox state
    setSelectAllTasks(!selectAllTasks);
  };

  return (
    <div>
      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="tab1">Grant Permissions</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tab2">Remove Permissions</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="tab1">
            <div className="workspace-container">
              <Button variant="primary" onClick={handleSubmit}>
                Add Permissions
              </Button>
              <Form.Group controlId="users">
                <Form.Label className="lablel1">user to invite:</Form.Label>
                <Select
                  options={users}
                  value={selectedusers}
                  isMulti={false}
                  isSearchable={true}
                  onChange={handleUsersChange}
                />
              </Form.Group>

              <div className="workspace-select">
                <Form.Label className="lablel1">
                  Select a Workspace :
                </Form.Label>
                <div className="worksace_select_">
                  <select
                    onChange={(e) => handleWorkspaceSelect(e.target.value)}
                  >
                    <option value="">Select</option>
                    {workspaces.map((workspace) => (
                      <option key={workspace.id} value={workspace.id}>
                        {workspace.name}
                      </option>
                    ))}
                  </select>
                  <label className="on_this_level">
                    <input
                      type="checkbox"
                      value="selectAll"
                      onChange={() => handleSelectOnWorkspaceLevel()}
                      checked={selectWorkspaceLevel}
                    />
                    Add On Workspace Level
                  </label>
                </div>
              </div>

              {!selectWorkspaceLevel && selectedWorkspace && (
                <div className="selected-workspace">
                  <div className="spaces-container">
                    <h3>Spaces:</h3>
                    <label className="on_this_level">
                      <input
                        type="checkbox"
                        value="selectAll"
                        onChange={() => handleSelectOnSpaceLevel()}
                        checked={selectSpaceLevel}
                      />
                      Add On Space Level
                    </label>

                    {spaces?.map((space) => (
                      <label key={space.id}>
                        <input
                          type="checkbox"
                          value={space.id}
                          onChange={() => handleSpaceSelect(space.id)}
                          checked={selectedSpaces.includes(space.id)}
                        />
                        {space.title}
                      </label>
                    ))}
                  </div>
                  {!selectSpaceLevel && selectedSpaces.length > 0 && (
                    <div className="boards-container">
                      <h3>Boards:</h3>
                      <label className="on_this_level">
                        <input
                          type="checkbox"
                          value="selectAll"
                          onChange={() => handleSelectOnBoardLevel()}
                          checked={selectBoardLevel}
                        />
                        Add On Board Level
                      </label>
                      {Object.values(boards).map((board) => (
                        <label key={board.id}>
                          <input
                            type="checkbox"
                            value={board.id}
                            onChange={() =>
                              handleBoardSelect(board.id, board.spaceId)
                            }
                            checked={selectedBoards[board.id]}
                          />
                          {board.boardName}
                        </label>
                      ))}
                    </div>
                  )}

                  {!selectBoardLevel &&
                    Object.entries(tasks).map(([boardId, boardTasks]) => (
                      <div key={boardId} className="tasks-container">
                        <h3>Tasks:</h3>
                        {/* bug */}
                        {boardTasks.map((task) => (
                          <div className="permission_type">
                            <Form.Label className="lablel1">
                              - {task.title}
                            </Form.Label>
                            <Form className="permission_type_row">
                              <label htmlFor={`radioGroup${task.id}-1`}>
                                <Form.Check
                                  type="radio"
                                  label="Admin: CRUD + invite"
                                  name={`radioGroup${task.id}`}
                                  value="1"
                                  checked={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "1"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleRadioChange(task.id, "1")
                                  }
                                  className={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "1"
                                    )
                                      ? "selected-radio"
                                      : ""
                                  }
                                  id={`radioGroup${task.id}-1`}
                                />
                              </label>
                              <label htmlFor={`radioGroup${task.id}-2`}>
                                <Form.Check
                                  type="radio"
                                  label="User: CRUD"
                                  name={`radioGroup${task.id}`}
                                  value="2"
                                  checked={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "2"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleRadioChange(task.id, "2")
                                  }
                                  className={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "2"
                                    )
                                      ? "selected-radio"
                                      : ""
                                  }
                                  id={`radioGroup${task.id}-2`}
                                />
                              </label>
                              <label htmlFor={`radioGroup${task.id}-3`}>
                                <Form.Check
                                  type="radio"
                                  label="Watcher"
                                  name={`radioGroup${task.id}`}
                                  value="3"
                                  checked={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "3"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleRadioChange(task.id, "3")
                                  }
                                  className={
                                    selectedPermission.find(
                                      (perm) =>
                                        perm.id === task.id && perm.type === "3"
                                    )
                                      ? "selected-radio"
                                      : ""
                                  }
                                  id={`radioGroup${task.id}-3`}
                                />
                              </label>
                            </Form>
                            {selectedPermission.some(
                              (perm) => perm.id === task.id
                            ) && (
                              <button
                                className="clear-button"
                                onClick={() => clearTaskSelection(task.id)}
                              >
                                Clear
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              )}
              <Button variant="primary" onClick={handleSubmit}>
                Add Permissions
              </Button>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tab2">
            <TaskPermissions_remove users={users}/>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default WorkspaceComponent;
