import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { BlockPicker } from "react-color";

import { createSpace } from "../../slices/workspaceSlice";
import { fetchSpaces } from "../../slices/workspaceSlice";

const CreateSpaceModal = ({ show, onClose, workspaceId }) => {
  const [title, setTitle] = useState("");
  const [workspace_id, setWorkspaceId] = useState(workspaceId);
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const workspaces = useSelector((state) => state.workspaces.data);

  const handleCreateSpace = () => {
    const newSpace = { workspace_id, title, description };
    let errors = [];

    if (!title) errors.push("title is required");
    if (!workspace_id) errors.push("workspace is required");

    if (errors.length > 0) {
      errors.forEach((item, index) => {
        toast.error(item);
      });
    } else {
      dispatch(createSpace(newSpace));
      dispatch(fetchSpaces(workspace_id));

      toast.success("success");
      navigate(`/w/${workspace_id}`);

      setTitle("");
      setDescription("");

      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Space</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="spaceName">
            <Form.Label>Space Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Space title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="workspace">
            <Form.Label>Workspace:</Form.Label>
            <Form.Control
              as="select"
              required
              value={workspace_id}
              onChange={(e) => setWorkspaceId(e.target.value)}
            >
              {
                workspaceId && <option value={workspaceId}> ------ current workspace ------</option>
              }

              {workspaces &&
                workspaces.map((workspace) => (
                  <option
                    key={workspace.id}
                    value={workspace.id}
                    // selected=
                  >
                    {workspace.name}  {workspaceId === workspace.id?" ------ current workspace ------ ":""}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="desc">
            <Form.Label>Space description:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Space description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCreateSpace}>
          Create Space
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateSpaceModal;
