import React, { useState } from "react";
import "./Main.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../common/Breadcrumb";
import { Button } from "react-bootstrap";
import { PlusCircle } from "phosphor-react";
import CreateWorkspaceModal from "../components/Workspace/createWorkspaceModal";

const MainPage = () => {
  const workspaces = useSelector((state) => state.workspaces.data);
  const breadcrumbs = [{ label: "Workspaces", link: "/w" }];

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container">
      <div className="header_breadcrumb">
        <Breadcrumb items={breadcrumbs} />
        <Button
          variant="success"
          className="mr-2"
          onClick={() => setShowModal(true)}
        >
          <PlusCircle size={22} />
        </Button>
      </div>
      <h5>Welcome to Your Workspaces</h5>
      {workspaces.length === 0 ? ( // Check if workspaces count is 0
        <div className="no-workspaces-message">
          <p>
            You don't have any workspaces. Create some workspaces to get
            started!
          </p>
        </div>
      ) : (
        <div className="workspace-list">
          {workspaces.map((workspace) => (
            <Link to={`${workspace.id}`} key={workspace.id}>
              <div className="workspace-card">
                <h2 className="workspace-name">{workspace.name}</h2>
                <p>{workspace.description}</p>
                <span className="workspace-type">{workspace.type}</span>

                <div className="space-list">
                  {/* {workspace.spaces &&
                    workspace.spaces.map((space) => (
                      <div key={space.id} className="space-card">
                        <span className="space-title">{space.title}</span>
                      </div>
                    ))} */}
                    <p>contains<b> {workspace.spaces?.length}</b> spaces</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {showModal && (
        <CreateWorkspaceModal show={showModal} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default MainPage;
