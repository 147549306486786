import { InputGroup, FormControl, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { addLabel } from "../../services/apiServices";
import { X } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardById, fetchCards } from "../../slices/cardSlice";
import { fetchList } from "../../slices/listSlice";
import { useParams } from "react-router-dom";
import { SwatchesPicker } from "react-color";

function AddLabel({ id, list_id , setlabelForm  }) {
  const [color, setColor] = useState("");
  const [name, setName] = useState("");
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const lists = useSelector((state) => state.lists.data);
  const { boardId } = useParams();

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleAddLabel = () => {
    addLabel(id, name, color);
    try {
      setName("");
      setColor("");
      setShow(false);
      dispatch(fetchCardById(id));
      dispatch(fetchList(boardId));
    } catch (error) {
      console.error("Error adding label:", error);
    }
    
    setlabelForm(false);
  };

  return (
    show && (
      <div
        className="row"
        style={{
          position: "absolute",
          top: "0px",
          left: 0,
          width: "fit-content",
          height: "fit-content",
          background: "#ccc",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 999,
          visibility: show ? "" : "hidden",
        }}
      >
        <div className="col-12 m-2">
          <label htmlFor="labelTitle" className="form-label">
            Label title:
          </label>
          <FormControl
            id="labelTitle"
            className="form-control"
            placeholder="Label title"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12 m-2">
          <label htmlFor="labelColor" className="form-label">
            Label color:
          </label>
          <SwatchesPicker color={color} onChange={handleChange} />
        </div>
        <div className="d-flex justify-content-center justify-content-sm-between">
           <Button  variant="secondary" onClick={handleAddLabel}>
            Add
          </Button>
          <Button    variant="secondary" onClick={() => setShow(false)}>
             Close 
          </Button>
        </div>
      </div>
    )
  );
}

export default AddLabel;
