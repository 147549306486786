import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiInstance from "../api"; // Import your axios instance

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async ({ cardId, page, eventsPerPage }) => {
    const response = await apiInstance.get(`/events/${cardId}`, {
      params: { page, eventsPerPage },
    });
    const events = response.data.events
    // console.log("events",events);
    return events;
  }
);

const initialState = {
  events: [],
  status: "idle",
  error: null,
};

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    clearEvents: (state) => {
        state.events = [];
      },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = "succeeded";

        state.events =  [...state.events, ...action.payload ];
      })


    // .addCase(fetchEvents.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    
    //     const { events, current_page, last_page } = action.payload;
    
    //     if (current_page === 1) {
    //       state.events = events;
    //     } else if (current_page > state.currentPage) {
    //       state.events = [...state.events, ...events];
    //     }
    
    //     state.currentPage = current_page;
    //     state.lastPage = last_page;
    //   })

      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { clearEvents } = eventSlice.actions;

export default eventSlice.reducer;
