import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import workspaceImage from "../../assets/d1f066971350650d3346.svg";
import "./style.css";
import { useDispatch } from "react-redux"; // Import useDispatch
import toast from "react-hot-toast";
import Select from "react-select";
import apiInstance from "../../api";
import { createWorkspace, fetchWorkspaces } from "../../slices/workspaceSlice";
import { useSelector } from "react-redux";

const InvitingUsers = ({ show, onClose, onCreateWorkspace }) => {
  const [users, setUsers] = useState([]);
  const [selectedusers, setSelectedusers] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState("");

  const [spaces, setSpaces] = useState([]);
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const fetchSpaces = async (workspaceId) => {
    try {
      const response = await apiInstance.get(
        `/workspaces/${workspaceId}/spaces`
      );
      // console.log(response);
      const spaceData = response.data.data.map((space) => ({
        id: space.id,
        name: space.title,
        selected: false, // Initially, no spaces are selected
      }));
      setSpaces(spaceData);
      console.log('spaces',spaces);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedWorkspace) {
      fetchSpaces(selectedWorkspace.value);
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    fetchUserEmails();
  }, []);

  const fetchUserEmails = async () => {
    try {
      const response = await apiInstance.get("/getUsersEmails"); // Replace with your API endpoint
      const userEmails = response.data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      setUsers(userEmails);
      // console.log("users",users);
    } catch (error) {
      console.error(error);
    }
  };

  const dispatch = useDispatch();
  const workspaces = useSelector((state) => state.workspaces.data);
  // console.log(workspaces);

  const workspacesOptions = workspaces.map((w) => ({
    value: w.id,
    label: w.name,
  }));

  const handleWorkspaceChange = (selectedOptions) => {
    setSelectedWorkspace(selectedOptions);
  };

  const handleUsersChange = (selectedOptions) => {
    setSelectedusers(selectedOptions);
  };

  // const handleInviteUsers = async () => {
  //   //handle api
  //   try {
  //     const workspace_id = selectedWorkspace ? selectedWorkspace.value : null;
  //     const user_ids = selectedusers.map((user) => user.value);
  //     const response = await apiInstance.post(`/inviteUsersToWorkspace`, {
  //       workspace_id,
  //       user_ids,
  //     });
  //     //   return response.data;
  //   } catch (error) {
  //     throw new Error("An error occurred while inviting users.");
  //   }

  //   // console.log(selectedusers, "_", selectedWorkspace);

  //   toast.success("invite users Successfully!", {
  //     duration: 2000, // Duration in milliseconds
  //     position: "top-center", // Toast position
  //   });
  //   onClose();
  // };

  const handleInviteUsers = async () => {
    try {
      const workspace_id = selectedWorkspace ? selectedWorkspace.value : null;
      const user_ids = selectedusers.map((user) => user.value);
      // const space_ids = spaces.map((space) => space.id);
      const space_ids = spaces.filter((space) => space.selected).map((space) => space.id);


      const response = await apiInstance.post(`/inviteUsersToWorkspace`, {
        workspace_id,
        user_ids,
        space_ids, // Include selected space IDs in the request
      });

      // Handle the response as needed
    } catch (error) {
      console.error("An error occurred while inviting users.", error);
      // Handle the error
    }

    toast.success("Invite users Successfully!", {
      duration: 2000,
      position: "top-center",
    });
    onClose();
  };

  const handleSpaceSelection = (spaceId) => {
    // alert(spaceId)
    // console.log("Selected Space ID:", spaceId);
    const updatedSpaces = spaces.map((space) =>
      space.id == spaceId ? { ...space, selected: !space.selected } : space
    );
    console.log("Updated Spaces:", updatedSpaces);
    setSpaces(updatedSpaces);
    // setSelectedSpaces(updatedSpaces.filter((space) => space.selected));
   };

  return (
    <Modal className="custom-modal" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <h5>Invite your team</h5>
            <p>
              Trello makes teamwork your best work. Invite your new team members
              to get going!
            </p>
            <Form>
              <Form.Group controlId="workspaces">
                <Form.Label>workspace</Form.Label>
                <Select
                  options={workspacesOptions}
                  value={selectedWorkspace}
                  isMulti={false}
                  isSearchable={true}
                  onChange={handleWorkspaceChange}
                />
              </Form.Group>
              <div className="spaces_checkbox_container">
              {spaces.map((space) => (
                <div key={space.id} className="checklist_item">
                  <input
                    className="space_checklist"
                    type="checkbox"
                    id={`space-${space.id}`}
                    checked={space.selected}
                    onChange={() => handleSpaceSelection(space.id)}
                  />
                  <label htmlFor={`space-${space.id}`}>{space.name}</label>
                </div>
              ))}
              </div>
              <Form.Group controlId="users">
                <Form.Label>users to invite:</Form.Label>
                <Select
                  options={users}
                  value={selectedusers}
                  isMulti={true}
                  isSearchable={true}
                  onChange={handleUsersChange}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col sm={6}>
            <img
              width="342"
              height="256"
              src={workspaceImage}
              alt="Workspace"
              role="presentation"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          I well do this Later
        </Button>
        <Button variant="primary" onClick={handleInviteUsers}>
          Invite to Workspace
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvitingUsers;
