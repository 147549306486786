import React, { useState, useEffect } from "react";
import apiInstance from "../../api";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditableText from "../common/EditableText";
import UserAvatar from "./UserAvatar";
import {
  deleteCommentAPI,
  editCommentAPI,
  fetchCommentsAPI,
  addReplyToCommentAPI,
} from "../../services/apiServices";
import "./CommentLists.css";
import { X } from "phosphor-react";
import { useDispatch } from "react-redux";
import { fetchCardById } from "../../slices/cardSlice";
import ConfirmationModal from "../../common/ConfirmationModal";
import CommentWithMention from "../common/CommentWithMention";

const CommentList = ({ CardId }) => {
  const [loading, setLoading] = useState(false);
  const [commentsToShow, setCommentsToShow] = useState(5);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const comments = useSelector((s) => s.cards.cards[0].comments);

  useEffect(() => {
    dispatch(fetchCardById(CardId));
  }, []);

  const userId = useSelector((state) => state.auth.user.user_id);

  const editComment = (params, content) => {
    editCommentAPI(params, content);
    dispatch(fetchCardById(CardId));
  };

  const addReplyToComment = (commentId, content) => {
    addReplyToCommentAPI(commentId, content);
    dispatch(fetchCardById(CardId));
  };

  //TODO
  const editReplyToComment = (commentId, content) => {
    addReplyToCommentAPI(commentId, content);
    dispatch(fetchCardById(CardId));
  };

  //TODO
  const handleDeleteReply = (replyId) => {
    setCommentToDelete(replyId);
    setShowModal(true);
  };

  const handleDeleteComment = (commentId) => {
    setCommentToDelete(commentId);
    setShowModal(true);
  };

  const confirmDeleteComment = () => {
    deleteCommentAPI(commentToDelete);
    setShowModal(false);
    setCommentToDelete(null);
    dispatch(fetchCardById(CardId));
  };

  const loadMoreComments = () => {
    setCommentsToShow(commentsToShow + 5);
  };

  const [replyForm, setReplyForm] = useState({}); // Track reply forms for each comment

  const toggleReplyComment = (commentId) => {
    setReplyForm((prev) => ({ ...prev, [commentId]: !prev[commentId] }));
  };

  // console.log(comments);
  return (
    <div className="comment-list">
      {comments &&
        comments.slice(0, commentsToShow).map((comment) => (
          <div key={comment.id}>
            <div className="comment">
              <UserAvatar
                className="comment-avatar"
                email={comment.user.email}
                firstName={comment.user.name}
                lastName={comment.user.name}
              />

              <div className="comment-content">
                <div className="comment-header">
                  <span className="user-name">{comment.user.name}</span>
                  <span className="comment-date">{comment.created_at}</span>
                  {(comment.user_id === userId)? (
                    <div className="comment_body">
                      <button
                        className="delete-comment nice-btn btn-cancel"
                        onClick={() => handleDeleteComment(comment.id)}
                      >
                        <X size={24} />
                      </button>

                      <EditableText 
                        title={comment.content}
                        apiMethod={editComment}
                        apiParams={comment.id}
                        type="textarea"
                        className="my_comment"
                      />
                    </div>
                  ):
                  <div
                  className="comment-text"
                  dangerouslySetInnerHTML={{ __html: comment.content }}
                />
                
                }
                </div>
              </div>

              <div className="comment-actions">
                <Button
                  className="reply_comment_btn"
                  onClick={() => toggleReplyComment(comment.id)}
                >
                  Reply
                </Button>
              </div>
            </div>

            {comment.replies && (
              <div className="replies-container">
                {comment.replies.map((reply) => (
                  <div key={reply.id} className="reply">
                    {/* delete reply */}
                    {reply.user_id === userId && (
                      <button
                        className="delete-comment nice-btn btn-cancel"
                        onClick={() => handleDeleteComment(comment.id)}
                      >
                        <X size={20} />
                      </button>
                    )}

                    <UserAvatar
                      className="reply_avatar"
                      email={reply.user.email}
                      firstName={reply.user.name}
                      lastName={reply.user.name}
                    />
                    <div className="reply-content">
                      {reply.user_id === userId ? (
                        <EditableText
                          title={reply.content}
                          apiMethod={addReplyToComment}
                          apiParams={comment.id}
                          type="textarea"
                          className="comment-reply"
                        />
                      ) : (
                        <div
                          className="div-editable"
                          dangerouslySetInnerHTML={{ __html: reply.content }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {replyForm[comment.id] && (
              <div className="reply-form">
                <EditableText
                  title="add Reply here"
                  apiMethod={addReplyToComment}
                  apiParams={comment.id}
                  type="textarea"
                  className="comment-reply"
                />
              </div>
            )}
          </div>
        ))}
      {loading ? <Spinner animation="border" variant="primary" /> : null}
      {comments?.length > commentsToShow && (
        <button onClick={loadMoreComments} disabled={loading}>
          Load More
        </button>
      )}

      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={confirmDeleteComment}
      />
    </div>
  );
};

export default CommentList;
