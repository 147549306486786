import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import { isDebugging } from "../config";
import { register, login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import authService from "../services/auth.service";

const Register = () => {
  const [successful, setSuccessful] = useState(false);

  let navigate = useNavigate();
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    name: "",
    email: "",
    password: "",
    c_password: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        "len",
        "The name must be between 3 and 20 characters.",
        (val) =>
          val && val.toString().length >= 3 && val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val && val.toString().length >= 6 && val.toString().length <= 40
      )
      .required("This field is required!"),
    c_password: Yup.string() // Add validation for c_password
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This field is required!"),
  });

  const state = useSelector((state) => state);
  // let registerResponse={};

  // const handleRegister = async (formValue) => {
  //   const { name, email, password, c_password } = formValue;
  //   setSuccessful(false);

  //   // dispatch(register({ name, email, password, c_password }));
  //   const resp = await authService.register(formValue);
  //   registerResponse= resp.data;
  //   console.log(registerResponse); //false or true

  //   // console.log(state);

  //   if (registerResponse.status) {
  //     dispatch(login({ email, password }))
  //       .unwrap()
  //       .then(() => {
  //         setSuccessful(true);
  //         navigate("/login");
  //       })
  //       .catch(() => {
  //         setSuccessful(false);
  //       });
  //   }
  // };

  const [validationErrors, setValidationErrors] = useState({});
  const handleRegister = async (formValue) => {
    const { name, email, password, c_password } = formValue;
    setSuccessful(false);

    const resp = await authService.register(formValue);
    const responseData = resp.data;
    console.log("responseData", responseData);

    if (!responseData.status) {
      setValidationErrors(responseData.data);
      console.log(formValue); // It may not log the updated state immediately due to its asynchronous nature
    }
     else {
      dispatch(login({ email, password }))
        .unwrap()
        .then(() => {
          setSuccessful(true);
          navigate("/login");
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };
  return (
    <div className="col-md-12 signup-form">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <Field name="name" type="text" className="form-control" />
                  <span className="text-danger"> {validationErrors.name}</span>
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field name="email" type="email" className="form-control" />
                  <span className="text-danger"> {validationErrors.email}</span>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                  />
                  <span className="text-danger">
                    {validationErrors.password}
                  </span>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">confirm Password</label>
                  <Field
                    name="c_password"
                    type="password"
                    className="form-control"
                  />
                  <span className="text-danger">
                    {validationErrors.c_password}
                  </span>

                  <ErrorMessage
                    name="c_password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign Up
                  </button>
                </div>
              </div>
            )}
          </Form>
        </Formik>

        <div className="mx-auto">
          <Link to="/login">I have Account , Loggin</Link>
        </div>
      </div>

      {message && (
        <div className="form-group">
          <div
            className={
              successful ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
          >
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
