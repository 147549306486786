import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import './Breadcrumb.css'
const Breadcrumb = ({ items }) => {
  return (
    <ul className="breadcrumb">
      {items.map((item, index) => (
        <li key={index} className='bread'>
          {item.link ? <Link to={item.link}>{item.label} &nbsp; / &nbsp; </Link> : item.label} 
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
