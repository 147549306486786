// components/Sidebar.js

import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import { FiArrowLeft, FiArrowRight, FiSettings } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { PlusCircle, Star } from "phosphor-react";
import CreateBoardModal from "./Board/CreateBoardModal";
import { FaPlus, FaMinus } from "react-icons/fa"; // Import the icons

import "./Sidebar.css";
import SidebarItems from "../common/SidebarItems";
import { useLocation } from "react-router-dom";

const Sidebar = ({ onSidebarStatusChange, isSidebarOpen_ }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(isSidebarOpen_);
  const [activeSubMenus, setActiveSubMenus] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevStatus) => !prevStatus);
    onSidebarStatusChange(!isSidebarOpen); // Call the function to update the status in the Main component
  };

  const [activeModal, setActiveModal] = useState(null);

  const workspaces = useSelector((state) => state.workspaces.data);
  // console.log("workspaces", workspaces);
  const boards = useSelector((state) => state.workspaces.boards);

  const handleModalShow = (modalName) => {
    setActiveModal(modalName);
  };
  const handleModalClose = () => {
    setActiveModal(null);
  };

  const { id } = useParams();
  const workspaceId = id;

  const handleSubMenuToggle = (id) => {
    if (activeSubMenus.includes(id)) {
      setActiveSubMenus(activeSubMenus.filter((subMenuId) => subMenuId !== id));
    } else {
      setActiveSubMenus([...activeSubMenus, id]);
    }
  };
  const location = useLocation();
  return (
    <>
      <Button
        variant="primary"
        className={`sidebar-toggle ${
          isSidebarOpen ? "sidebar-open" : "sidebar-close"
        }`}
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? <FiArrowLeft /> : <FiArrowRight />}
      </Button>
      <Collapse in={isSidebarOpen}>
        <div className={`sidebar ${isSidebarOpen ? "open" : "sidebar-close"}`}>
          <div className="sidebar-section">
            <ul>
              <Link
                className={`sidebar-item ${
                  location.pathname === "/boards" ? "active" : ""
                }`}
                to="/"
              >
                Boards
              </Link>
              <Link
                className={`sidebar-item ${
                  location.pathname === "/members" ? "active" : ""
                }`}
                to="/"
              >
                Members
              </Link>
              <Link
                className={`sidebar-item ${
                  location.pathname === "/links" ? "active" : ""
                }`}
                to="/links"
              >
                Links
              </Link>
              {/* <Link
                className={`sidebar-item ${
                  location.pathname === "/chat" ? "active" : ""
                }`}
                to="/chat"
              >
                chats
              </Link> */}
              <Link
                className={`sidebar-item ${
                  location.pathname === "/notifications" ? "active" : ""
                }`}
                to="/notifications"
              >
                Notifications
              </Link>
              <Link
                className={`sidebar-item settings_item ${
                  location.pathname === "/settings" ? "active" : ""
                }`}
                to=""
              >
                <FiSettings /> &nbsp; workspace settings
              </Link>
            </ul>
          </div>
          <div className="sidebar-section">
            <h5 className="my_workspaces">Shared Workspaces</h5>
            <SidebarItems items={workspaces} type="team" />
            <h5 className="my_workspaces">My Workspaces</h5>
            <SidebarItems items={workspaces} type="personal" />
          </div>

          <div className="sidebar-section">
            {/* <div className="d-flex flex-row justify-content-between">
              <h5>Your Boards </h5>
              <PlusCircle
                className="addBoardIcon"
                size={22}
                onClick={() => handleModalShow("createBoard")}
              />
            </div> */}

            {/* <ul>
              {boards && boards.map((board) => (
                <div key={board.id} className="sidebar-item-container">
                  <Link
                    className="sidebar-item"
                    to={`/w/${workspaceId}/${board.id}`}
                  >
                    {board.boardName} 
                  </Link>
                  <Star
                    className="star"
                    onClick={() => alert("make this favorite")}
                    size={16}
                  />
                </div>
              ))}
            </ul> */}
          </div>
        </div>
      </Collapse>

      <CreateBoardModal
        show={activeModal === "createBoard"}
        onClose={handleModalClose}
        // onCreateBoard={handleCreateBoard}
      />
    </>
  );
};

export default Sidebar;
