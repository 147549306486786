import React, { useState ,useEffect} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBoard, fetchBoards } from '../../slices/boardSlice';
import { useParams , useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BlockPicker  } from 'react-color'; 



const CreateBoardModal = ({  show, onClose }) => {

  const dispatch = useDispatch();
 
  const {spaceId} = useParams();
  const [background, setBackground] = useState('#2BDEF2');
  const [boardName, setTitle] = useState('');
  const [space_id, setSpaceId] = useState(spaceId);
  const [visibility, setVisibility] = useState('private');


  const handleChange = (newColor) => {
    setBackground(newColor.hex);
  };

  const spaces = useSelector((state) => state.spaces);
  const state = useSelector((state) => state);
  //  console.log("state",state);

  // const boards = useSelector((state) => state.workspaces.boards);
 
  //TODOS Perform validation (e.g., check if title is not empty, etc.)
  //TODOS C# tool : field names 
  //TODOS api res at client same as server
  const handleCreateBoard = () => {
    let status = "On Going";
    const newBoard = { space_id, background,  boardName, visibility, status};
    let errors = []

    if(!boardName ) errors.push('boardName is required')
    if(!space_id ) errors.push('space is required')
 
    if(errors.length > 0)
    {
      errors.forEach((item, index)=>{toast.error(item)})
     }
    else
    {
      dispatch(createBoard(newBoard));
       dispatch(  fetchBoards({
        spaceId,
        search: '',
        sortField:'id',
        sortOrder: 'asc', // or 'desc'
        groupField: 'status',
      }));
       toast.success("success")
      // navigate(`/w/${space_id}`)
      onClose();
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Board</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        
        <Form.Group controlId="color">
            <Form.Label>background</Form.Label>
            <BlockPicker  color={background} onChange={handleChange} />
          </Form.Group>

          <Form.Group controlId="boardName">
            <Form.Label>Board Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter board title"
              value={boardName}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="workspace">
            <Form.Label>Space:</Form.Label>
            <Form.Control
              as="select"
              required
              value={space_id}
              onChange={(e) => setSpaceId(e.target.value)}
            >
              {
                spaceId && <option value={spaceId}> ----- current space -----</option>
              }
              {spaces && spaces.map((space) => (
                <option key={space.id} value={space.id}>
                  {space.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="visibility">
            <Form.Label>Visibility:</Form.Label>
            <Form.Control
              as="select"
              value={visibility}
              required
              onChange={(e) => setVisibility(e.target.value)}
            >
              <option value="workspace">Space Visibility</option>
              <option value="private">Private</option>
              <option value="public">Public</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCreateBoard}>
          Create Board
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateBoardModal;
