import { InputGroup, FormControl, Button } from "react-bootstrap";
import React, { useState } from "react";
import { addDueDate } from "../../services/apiServices";
import { X } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "../../slices/listSlice";
import { fetchCardById, fetchCards } from "../../slices/cardSlice";
import { useParams } from "react-router-dom";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

function AddDueDate({ id, list_id }) {
  const [startDate, setstartDate] = useState(new Date());
  const [dueDate, setdueDate] = useState(new Date());

  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const { boardId } = useParams();


  function formatDate(date)
  {
    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    const seconds = String(dateObject.getSeconds()).padStart(2, '0');
    
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }
  const handleAdd = () => {
    try {

      let date1 = formatDate(startDate)
      let date2 = formatDate(dueDate)

      addDueDate(id, date1 , date2);
      //   setDate("");
      setShow(false);

      dispatch(fetchCardById(id));
      dispatch(fetchList(boardId));
    } catch (error) {
      console.error("Error adding label:", error);
      // Handle errors...
    }
  };

  return (
    show && (
      <div
         style={{
          position: "absolute",
          top: "160px",
          left: -130,
          width: "fit-content",
          height: "fit-content",
          background: "#ccc",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 999,
          visibility: show ? "" : "hidden",
        }}
      >
        <div className="col-12 m-2">
           <label htmlFor="labelTitle" className="form-label">
            start date :
          </label>
          <DateTimePicker
            format="yyyy-MM-dd HH:mm:ss"
            className="col-sm-3"
            onChange={setstartDate}
            value={startDate}
          />
          <label htmlFor="labelTitle" className="form-label">
            due Dates :
          </label>
          <DateTimePicker
            format="yyyy-MM-dd HH:mm:ss"
            className="col-sm-3"
            onChange={setdueDate}
            value={dueDate}
          />
        </div>

        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleAdd}>
            Add
          </Button>
          <Button variant="secondary" onClick={() => setShow(false)}>
             Close 
          </Button>
        </div>
      </div>
    )
  );
}

export default AddDueDate;
