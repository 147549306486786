import React, { useEffect, useState } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  ConversationList,
  Conversation,
  Avatar,
  ConversationHeader,
  Search,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import img1 from "../assets/avatar.png";
import { apiInstance } from "../services/apiServices";
import "./Chat.css";
import { useSelector } from "react-redux";

function Chat() {
  const [users, setUsers] = useState([]);
  const [filterdUsers, setFilteredUsers] = useState([]); //sidebar search

  const [selectedChat, setSelectedChat] = useState({}); //sidebar search
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const currentUser = useSelector((state) => state.auth.user);
  // console.log("currentUser", currentUser);

  const sendMessage = async (e) => {
    try {
      await apiInstance.post("/messages", {
        recipient_id: selectedChat.id,
        content: e,
      });
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiInstance.get("/getAllUsers");
        setUsers(response.data);
        setFilteredUsers(response.data);
      } catch (error) {
        console.error("Error fetching user options:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await apiInstance.get(`/messages/${selectedChat.id}`);
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

  }, [selectedChat.id]);

  const filterUsers = (username) => {
    const searchText = username.toLowerCase();
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(searchText)
    );

    if (username.trim() === "") {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(filtered);
    }
  };

  return (
    <div className="chatPage_div">
      <div style={{ position: "relative", height: "600px" }}>
        <MainContainer>
          <Sidebar position="left">
            <Search placeholder="Search..." onChange={filterUsers} />
            <ConversationList>
              {filterdUsers?.map((user) => (
                <Conversation
                  key={user.id}
                  name={user.name}
                  lastSenderName={user.name}
                  info="hello last msg sent"
                  active={false}
                  unreadCnt={10}
                  lastActivityTime="43 min"
                  onClick={() => {
                    // alert(user.id)
                    setSelectedChat({
                      type: "user",
                      id: user.id,
                      name: user.name,
                    });
                  }}
                >
                  <Avatar src={user.avatar || img1} status="unavailable" />{" "}
                  {/*status: available , eager , dnd , invisible , away ,   */}
                </Conversation>
              ))}
            </ConversationList>
          </Sidebar>

          <ChatContainer>
            <ConversationHeader>
              <Avatar src="https://robohash.org/d6e8d7bdcfccbf254356de54cb7404c0?set=set1&bgset=&size=200x200" />

              <ConversationHeader.Content
                userName={selectedChat.name}
                info="last active sine 10 minutes ago"
              ></ConversationHeader.Content>
            </ConversationHeader>
            <MessageList>
              {messages.map((msg) => (
                <Message
                  key={msg.id}
                  className="message"
                  model={{
                    message: msg.content,
                    sentTime: "just now",
                    sender: "Joe",
                    direction:
                      currentUser.user_id == msg.user_id
                        ? "outgoing"
                        : "ingoing", // :
                    position: "single",
                    // type:"message",
                  }}
                >
                  <Message.Footer sentTime={msg.created_at} />
                </Message>
                // <Avatar src="https://robohash.org/d6e8d7bdcfccbf254356de54cb7404c0?set=set4&bgset=&size=200x200" />
                // </Message>
              ))}
              {/* <TypingIndicator content="Akane is typing" /> */}
            </MessageList>

            <MessageInput
              placeholder="Type message here"
              onSend={sendMessage}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
}

export default Chat;
