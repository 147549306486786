import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchWorkspaces } from "../slices/workspaceSlice";
import { login, loginFromHRSystem,clear, loginWithGoogle, logout } from "../slices/auth";
import { clearMessage } from "../slices/message";
import toast from "react-hot-toast";
import vr_image from "../assets/img.webp";
import { isDebugging } from "../config";

import "./Login.css";

const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);


  //login from HR system
  const urlParams = new URLSearchParams(window.location.search);
  const userData = urlParams.get("user");
  useEffect(() => {
    if (userData) {
      // localStorage.clear();
      // dispatch(logout());
      // alert(1)
      const userObject =JSON.parse(decodeURIComponent(userData)) // 
      localStorage.setItem("user",JSON.stringify(userObject) );
     
      dispatch(loginFromHRSystem(userObject));
      // console.log(auth);
    }
  }, []);

  //only debugging 
  // useEffect(() => {
  //   console.log(auth); // This log will show the updated auth state
  // }, [auth]);
  //  console.log('auth',auth);


    useEffect(() => {
    if(isLoggedIn)
    {
      navigate('/w')
    }
  }, [auth]);


  useEffect(() => {
    dispatch(clearMessage());
    // console.log("fetchWorkspaces");
  }, [dispatch]);

  const signInWithGoogle = async () => {
    await dispatch(loginWithGoogle());
    // console.log("User Details:", state);
    // navigate("/w");
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue) => {
    const { email, password } = formValue;
    setLoading(true);

    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        navigate("/w");

        toast.success("You logged in Successfully", {
          duration: 2000,
          position: "top-center",
        });
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error logging in!", {
          duration: 3000,
          position: "top-center",
        });
      });
  };

  useEffect(() => {
    // dispatch(fetchWorkspaces());
    if (auth.isLoggedIn) {
      // dispatch(fetchWorkspaces());
    }
  }, []); //auth.user, dispatch

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const state = useSelector((state) => state);

  // if(!userData)
  // if (isLoggedIn) {
  //   return <Navigate to="/w" />;
  // }

  return (
    <div className="col-sm-12 login-form">
      <div className="card card-container">
        <img src={vr_image} alt="profile-img" className="profile-img-card" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="name">email</label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loading}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>
          </Form>
        </Formik>

        <div className="mx-auto">
          <Link to="/register">register new account</Link>
        </div>
        <button className="secondary" onClick={signInWithGoogle}>
          Sign in with Google
        </button>
      </div>
      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
