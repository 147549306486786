import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiInstance from '../api';
import store from '../store';




export const inviteUsersToCard = createAsyncThunk(
  'cards/inviteUsersToCard',
  async ({ cardId, userIds }) => {
    try {
      const response = await apiInstance.post(`cards/${cardId}/invite`, { userIds });
      return response.data;
    } catch (error) {
      throw new Error('Error inviting users to card');
    }
  }
);


export const clearCards = createAsyncThunk('cards/clearCards', async () => {
  // Perform any additional logic here, if needed
  return null;
});

//1-8-2023
export const fetchCardById = createAsyncThunk('cards/fetchCardById', async (cardId) => {
  try {
    const response = await apiInstance.get(`cards/${cardId}`);
    // console.log("fetched" , response.data);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching card by ID');
  }
});

export const addLabel = createAsyncThunk('cards/addLabel', async ({ cardId, labelData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-label`, labelData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding label');
  }
});

// Add a comment to a card
export const addComment = createAsyncThunk('cards/addComment', async ({ cardId, commentData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-comment`, commentData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding comment');
  }
});

// Add a checklist to a card
export const addChecklist = createAsyncThunk('cards/addChecklist', async ({ cardId, checklistData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-checklist`, checklistData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding checklist');
  }
});

// Add a description to a card
export const addDescription = createAsyncThunk('cards/addDescription', async ({ cardId, descriptionData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-description`, descriptionData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding description');
  }
});

// Add a due date to a card
export const addDueDate = createAsyncThunk('cards/addDueDate', async ({ cardId, dueDateData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-due-date`, dueDateData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding due date');
  }
});

// Add an attachment to a card
export const addAttachment = createAsyncThunk('cards/addAttachment', async ({ cardId, attachmentData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-attachment`, attachmentData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding attachment');
  }
});

// Add a cover to a card
export const addCover = createAsyncThunk('cards/addCover', async ({ cardId, coverData }) => {
  try {
    const response = await apiInstance.post(`cards/${cardId}/add-cover`, coverData);
    return response.data;
  } catch (error) {
    throw new Error('Error adding cover');
  }
});
//-----------------------------------------------------------------------------------------

// Fetch cards for a specific list
export const fetchCards = createAsyncThunk('cards/fetchCards', async (listId) => {
  try {
    const response = await apiInstance.get(`lists/${listId}/card`);
     return response.data;
  } catch (error) {
    throw new Error('Error fetching cards');
  }
});

// Create a new card
export const createCard = createAsyncThunk('cards/createCard', async (cardData) => {
  try {
    const response = await apiInstance.post('cards', cardData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating card');
  }
});

// Update a card
export const updateCard = createAsyncThunk('cards/updateCard', async (cardData) => {
  try {
    const response = await apiInstance.put(`cards/${cardData.id}`, cardData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating card');
  }
});

// Delete a card
export const deleteCard = createAsyncThunk('cards/deleteCard', async (cardId) => {
  try {
    await apiInstance.delete(`cards/${cardId}`);
    return cardId;
  } catch (error) {
    throw new Error('Error deleting card');
  }
});

 

const initialState = {
  cards: [],
  status: 'idle',
  error: null,
};

const cardSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cards = action.payload;
      })
      .addCase(fetchCards.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createCard.fulfilled, (state, action) => {
        state.cards.push(action.payload);
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        const updatedCardIndex = state.cards.findIndex((card) => card.id === action.payload.id);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex] = action.payload;
        }
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        state.cards = state.cards.filter((card) => card.id !== action.payload);
      })
      //--------------------------
      .addCase(addLabel.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].labels.push(action.payload);
        }
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].comments.push(action.payload);
        }
      })
      .addCase(addChecklist.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].checklists.push(action.payload);
        }
      })
      .addCase(addDescription.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].description = action.payload.description;
        }
      })
      .addCase(addDueDate.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].due_date = action.payload.due_date;
        }
      })
      .addCase(addAttachment.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].attachments.push(action.payload);
        }
      })
      .addCase(addCover.fulfilled, (state, action) => {
        const cardId = action.payload.card_id;
        const updatedCardIndex = state.cards.findIndex((card) => card.id === cardId);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex].cover = action.payload.cover;
        }
      })
      .addCase(fetchCardById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCardById.fulfilled, (state, action) => {
        state.status = 'succeeded';

        // If the card with the specified ID already exists in the state, update it
        const updatedCardIndex = state.cards.findIndex((card) => card.id === action.payload.id);
        if (updatedCardIndex !== -1) {
          state.cards[updatedCardIndex] = action.payload;
        }
        // Otherwise, add the fetched card to the state
        else {
          state.cards.push(action.payload);
        }
      })
      .addCase(fetchCardById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(clearCards.fulfilled, (state) => {
        state.cards = [];
      })
      //--------------------------
      .addCase(inviteUsersToCard.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(inviteUsersToCard.fulfilled, (state, action) => {
        // Update state with the response data if needed
      })
      .addCase(inviteUsersToCard.rejected, (state, action) => {
        // Handle error state if needed
      });


      
      
  },
});

export default cardSlice.reducer;
