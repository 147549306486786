import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import workspaceReducer  from "./slices/workspaceSlice";
import boardReduce  from "./slices/boardSlice";
import listReduce from './slices/listSlice'
import cardReduce from './slices/cardSlice'
import eventReduce from './slices/eventSlice'
const reducer = {
  auth: authReducer,
  message: messageReducer,
  
  workspaces: workspaceReducer,
  boards: boardReduce,
  lists:listReduce,
  cards:cardReduce,
  events:eventReduce,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
