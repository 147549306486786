import React, { useState } from "react";
import TrelloCard from "../Card/TrelloCard";
import AddCard from "../Card/AddCard";
import { DotsThreeOutline } from "phosphor-react";
import { Droppable, Draggable } from "react-beautiful-dnd"; // Add this import
import { updateListTitle } from "../../services/apiServices";
import "./TrelloList.css";
import EditableText from "../common/EditableText";
import { useDispatch } from "react-redux";
import { fetchList } from "../../slices/listSlice";
import { useParams } from "react-router-dom";

function TrelloList({ className, id, title, cards }) {
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const updateList = (params, updateTitle) => {
    updateListTitle(id, updateTitle, boardId);
    dispatch(fetchList(boardId));
  };


  const [showDetailsOfCard, setshowDetailsOfCard] = useState(false)

  const handleCardKeyDown = (e) => {
    if (e.keyCode === 13) {
      setshowDetailsOfCard(true);
    }
  };

  return (
    <div className={className + " List"} >
      {/* <input name="list__id" type="hidden" value={id} /> */}
      <div className="d-flex flex-row justify-content-between p-1">
        <EditableText title={title} apiMethod={updateList} apiParams={id} />
        <DotsThreeOutline size={12} />
      </div>
      {/* Add Droppable wrapper */}
      <Droppable droppableId={"List" + id} type="CARD">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {cards && cards.length > 0 ? (
              cards.map((card, index) => (
                <Draggable
                  key={card.id}
                  draggableId={"Card" + card.id}
                  index={index}
                  
                >
                  {(provided, snapshot) => (
                    <div
                    onKeyDown={handleCardKeyDown} tabIndex="0"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                     
                    >
                      <TrelloCard
                       className={` ${snapshot.isDragging ? "dragged-item" : ""}`}
                        key={card.position}
                        id={card.id}
                        list_id={id}
                        card={card}
                        // showDetailsOfCard={showDetailsOfCard}
                      />
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <div>No cards</div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <AddCard list__id={id} />
    </div>
  );
}

export default TrelloList;
