import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FloppyDisk, X } from "phosphor-react";

import { useDispatch } from "react-redux";
import { createCard, fetchCards } from "../../slices/cardSlice";
import { fetchList } from "../../slices/listSlice";
import "./AddList.css";
import { useParams } from "react-router-dom";
import SaveCancelButton from "../common/SaveCancelButton";

const AddCard = ({ list__id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("Add a Task");
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();
  let { boardId } = useParams();

  function handleAddCard() {
    const newCard = { title, list__id };
    setText(""); // Clear input text
    let res = dispatch(createCard(newCard));
    dispatch(fetchList(boardId));
    setIsEditing(false);
    setTitle("");
    setText("Add a Card");
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setTitle(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddCard();
    }
  };

  return (
    <>
      {isEditing ? (
        <div className="addCard">
          <input
            type="text"
            value={title}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown} // Handle Enter key press
            autoFocus
            onBlur={handleInputBlur}
          />
          <SaveCancelButton onSave={handleAddCard} onCancel={handleInputBlur} />
        </div>
      ) : (
        <div onClick={handleEditClick} className="add-new-card">
          {text}
        </div>
      )}
    </>
  );
};

export default AddCard;
