import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsFillCalendarFill,
} from "react-icons/bs";
import { IconContext } from "react-icons";
import { Line, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import apiInstance from "../../api";
import { colors } from "../../config";
import "./Statistics.css";

Chart.register(...registerables);

const Statistics = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalAdmins: 0,
    totalWorkspaces: 0,
    totalCards: 0,
  });

  const [chart1Data, setChart1Data] = useState([]); //chart 1
  const [workspaces, setWorkspaces] = useState([]); //chart 2
  useEffect(() => {
    fetchDailyActivity();
    fetchWorkspacesWithBoards();
  }, []);

  const fetchWorkspacesWithBoards = async () => {
    try {
      const response = await apiInstance.get("/workspaces-with-boards");
      setWorkspaces(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDailyActivity = async () => {
    try {
      const response = await apiInstance.get("/daily-cards-chart-data");
      setChart1Data(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const labels = chart1Data.map((data) => data.day);
  const dataPoints = chart1Data.map((data) => data.card_count);

  const lineChartData = {
    labels,
    datasets: [
      {
        label: "Daily Created Tasks",
        data: dataPoints,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: colors,
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await apiInstance.get("/total-stats");
      setStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Generate some random data for the charts
  const randomData = () => {
    return Array.from({ length: 7 }, () => Math.floor(Math.random() * 50));
  };

  // Chart.js configuration
  const barChartData = {
    labels: workspaces.map((workspace) => workspace.name),
    datasets: [
      {
        label: "Total Boards",
        data: workspaces.map((workspace) => workspace.boards_count),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };
  // Customize the Y-axis options
  const yAxesOptions = [
    {
      ticks: {
        beginAtZero: true, // Start the Y-axis at 0
        stepSize: 2, // Customize the step size
      },
    },
  ];

  // Customize the chart options
  const chartOptions = {
    scales: {
      y: yAxesOptions,
    },
  };

  return (
    <div className="Statistics mt-5">
      <h2>Statistics</h2>
      <Row className="">
        {/* Widgets */}
        <Col xs={6} md={3} className="widget-container">
          <div className="widget">
            <IconContext.Provider value={{ size: "3em" }}>
              <BsFillPersonFill />
            </IconContext.Provider>
            <p>Total Users</p>
            <span>{stats.totalUsers}</span>
          </div>
        </Col>
        <Col xs={6} md={3}>
          <div className="widget">
            <IconContext.Provider value={{ size: "3em" }}>
              <BsFillPersonFill />
            </IconContext.Provider>
            <p>Super Admins</p>
            <span>{stats.totalAdmins}</span>
          </div>
        </Col>
        <Col xs={6} md={3}>
          <div className="widget">
            <IconContext.Provider value={{ size: "3em" }}>
              <BsFillCalendarFill />
            </IconContext.Provider>
            <p>Total Workspaces</p>
            <span>{stats.totalWorkspaces}</span>
          </div>
        </Col>
        <Col xs={6} md={3}>
          <div className="widget">
            <IconContext.Provider value={{ size: "3em" }}>
              <BsFillEnvelopeFill />
            </IconContext.Provider>
            <p>Total tasks</p>
            <span>{stats.totalCards}</span>
          </div>
        </Col>
      </Row>
      <Row>
        {/* Charts */}
        <Col md={4}>
          <Bar data={lineChartData} />
        </Col>
        <Col md={4}>
          <Bar data={barChartData} options={chartOptions} height={200}/>
        </Col>
        <Col md={4}>
          <Line data={barChartData} options={chartOptions} height={200}/>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
