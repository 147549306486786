import React, { useState } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import workspaceImage from "../../assets/d1f066971350650d3346.svg";
import "./style.css";
import { useDispatch } from "react-redux"; // Import useDispatch
import toast from 'react-hot-toast';

import { createWorkspace , fetchWorkspaces } from "../../slices/workspaceSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CreateWorkspaceModal = ({ show, onClose, onCreateWorkspace }) => {
  const [workspaceName, setWorkspaceName] = useState(null);
  const [workspaceType, setWorkspaceType] = useState("team");
  const [workspaceDescription, setWorkspaceDescription] = useState(" ");

  const dispatch = useDispatch();
 const workspaces = useSelector((state) => state.workspaces.data)
 let navigate = useNavigate();
  const handleCreateWorkspace = () => {
      //validations
  let errors=[];

    // Prepare the workspace data to be sent to the onCreateWorkspace function
    const newWorkspace = {
      name: workspaceName,
      type: workspaceType,
      description: workspaceDescription,
    };
    if(!workspaceName) errors.push('workspaceName is required')
    if(errors.length > 0)
    {
      errors.forEach((item, index)=>{toast.error(item)})
     }
    else
    {
      dispatch(createWorkspace(newWorkspace));
      
      // let addedWorkspaceId = workspaces[workspaces.length-1].id
      // navigate(`/w/${addedWorkspaceId}`)
      dispatch(fetchWorkspaces());

      toast.success('created Workspace Successfully!', {
        duration: 2000, // Duration in milliseconds
        position: 'top-center', // Toast position
      });

      setWorkspaceType("");
      setWorkspaceDescription("")
      setWorkspaceName("");

      onClose();
    }
  };

 

  return (
    <Modal className="custom-modal" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create a Workspace</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <h5>Let's build a Workspace</h5>
            <p>
              Boost your productivity by making it easier for everyone to access
              boards in one location.
            </p>
            <Form>
              <Form.Group controlId="workspaceName">
                <Form.Label>Workspace Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter workspace name"
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="workspaceType">
                <Form.Label>Workspace Type:</Form.Label>
                <Form.Control
                  as="select"
                  value={workspaceType}
                  onChange={(e) => setWorkspaceType(e.target.value)}
                >
                  <option value="team">Team</option>
                  <option value="personal">Personal</option>
                  <option value="project">Project</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="workspaceDescription">
                <Form.Label>Workspace Description (Optional):</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter workspace description"
                  value={workspaceDescription}
                  onChange={(e) => setWorkspaceDescription(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col sm={6}>
          <img width="342" height="256"    src={workspaceImage} alt="Workspace" role="presentation"/>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCreateWorkspace}>
          Create Workspace
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateWorkspaceModal;
