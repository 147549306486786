// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
 
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANXHWDwE2pmDqGx0iKk26hKqPZMYHdQ7o",
  authDomain: "trello-clone-ec69e.firebaseapp.com",
  projectId: "trello-clone-ec69e",
  storageBucket: "trello-clone-ec69e.appspot.com",
  messagingSenderId: "439054321484",
  appId: "1:439054321484:web:e01b6eccc616260c3c71e9"
};

 
 
// Initialize Firebase
 const app = initializeApp(firebaseConfig);
 
export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
 