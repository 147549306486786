import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Dropdown,
  Button,
  FormControl,
  InputGroup,
  NavDropdown,
} from "react-bootstrap";
import EventBus from "../common/EventBus";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkspaces,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
} from "../slices/workspaceSlice";
import { logout } from "../slices/auth";
import CreateWorkspaceModal from "./Workspace/createWorkspaceModal";
import InvitingUsers from "./Workspace/invitingUsers";
import CreateBoardModal from "./Board/CreateBoardModal";
import { User, UserGear, UserSwitch } from "phosphor-react";
import { toast } from "react-hot-toast";
import { isDebugging } from "../config";



import "./Header.css";
import CreateSpaceModal from "./Space/CreateSpaceModal";

export default function Header() {
  const [showForm, setShowForm] = useState(false);
  let navigate = useNavigate();

  //we have two modals
  const [activeModal, setActiveModal] = useState(null);
  const handleToggle = (isOpen) => {
    setShowForm(isOpen);
  };

  const handleModalShow = (modalName) => {
    setActiveModal(modalName);
  };
  const handleModalClose = () => {
    setActiveModal(null);

    //TODOS  show invite users if ???
    if (activeModal == "createWorkspace" && true)
      handleModalShow("inviteUsers");
  };

  const handleCreateBoard = () => {
    // Handle the create board action here
    // For example, you can access form data and make API requests
    // Reset form fields and close the modal

    setActiveModal(false);
  };
  const handleCreateWorkspace = () => {
    // Reset form fields and close the modal
    setActiveModal(false);
  };

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const workspaces = useSelector((state) => state.workspaces.data);
  const state = useSelector((state) => state);
  // console.log("state",auth);

  useEffect(() => {
    // console.log("init header comp");
    dispatch(fetchWorkspaces());
  }, []);

  // useEffect(() => {
  //   // Only fetch workspaces if auth.user is ready
  //   if (auth.isLoggedIn) {
  //     dispatch(fetchWorkspaces());
  //   }
  // }, [auth.user, dispatch]);

  // const handleCreateWorkspace = () => {
  //   dispatch(createWorkspace({ description: "New Workspace" }));
  // };

  const handleUpdateWorkspace = (workspaceId, newDescription) => {
    dispatch(updateWorkspace({ id: workspaceId, description: newDescription }));
  };

  const handleDeleteWorkspace = (workspaceId) => {
    dispatch(deleteWorkspace(workspaceId));
  };

  // if (status === "loading") {
  //   return <div>Loading...</div>;
  // }

  const logout_ = () => {
    // EventBus.dispatch("logout");
    navigate("/login");
    dispatch(logout());
    toast.error("you are out of the system right now");
  };

  //pusher

  const [message, setMessage] = useState("");
 
 
 

  return (
    <div>
      <Navbar className="navbar" bg="light" expand="sm">
        <Navbar.Brand
          as={Link}
          to="/"
          style={{ backgroundColor: isDebugging ? "red" : "" }}
        >
          VRD Dashboard
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#link">Link</Nav.Link> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link}>workspaces </Dropdown.Toggle>

              <Dropdown.Menu>
                {workspaces &&
                  workspaces.map((item) => (
                    <Dropdown.Item as={Link} to={`/w/${item.id}`} key={item.id}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link}>create </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleModalShow("createWorkspace")}
                >
                  create a Workspace
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleModalShow("createSpace")}>
                  create a Space
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleModalShow("createBoard")}>
                  create a Board
                </Dropdown.Item>

                <Dropdown.Item onClick={() => handleModalShow("inviteUsers")}>
                  invite users to workspace
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Nav>
            {/* <Button variant="success" className="mr-2">
          <BsPlus className="mr-1" />
        </Button> */}
          </Nav>

          <Nav className="float-right">
            {auth.user && auth.user.is_admin ? (
              <Link className="go-to-admin" to="/admin/stats">
                <UserSwitch size={44} />
              </Link>
            ) : null}

            <InputGroup>
              <FormControl
                type="text"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
            </InputGroup>
            {auth.user && (
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link}>
                  {auth.user.avatar ? (
                    <img
                      className="avatar-img"
                      src={auth.user.avatar}
                      width="100"
                    />
                  ) : (
                    <User size={32} />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  <Dropdown.Item>{auth.user.name}</Dropdown.Item>
                  {/* <Dropdown.Item>{auth.user.email}</Dropdown.Item> */}
                  <Dropdown.Item>profile *later*</Dropdown.Item>
                  <Dropdown.Item onClick={logout_}>logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* <AddWorkspace/> */}
      <CreateBoardModal
        show={activeModal === "createBoard"}
        onClose={handleModalClose}
        onCreateBoard={handleCreateBoard}
      />
      <CreateWorkspaceModal
        show={activeModal === "createWorkspace"}
        onClose={handleModalClose}
        onCreateWorkspace={handleCreateWorkspace}
      />
      <CreateSpaceModal 
       show={activeModal === "createSpace"}
       onClose={handleModalClose}
       onCreateWorkspace={handleCreateWorkspace}
       />
      <InvitingUsers
        show={activeModal === "inviteUsers"}
        onClose={handleModalClose}
      />
    </div>
  );
}
