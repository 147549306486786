import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  UserCircle,
  Users,
  Sticker,
  ListChecks,
  Calendar,
  Paperclip,
  PaintBrushBroad,
  X,
  TextAlignLeft,
  UsersFour,
  HourglassMedium,
  ChatText,
  TagSimple,
} from "phosphor-react";
import ActionBtn from "./ActionBtn";
import AddLabel from "./AddLabel";
import AddCheckList from "./AddCheckList";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardById, fetchCards } from "../../slices/cardSlice";
import {
  updateCheckList,
  updateCardTitle,
  addDescription,
  updateLabel,
  addCommentAPI,
  updateCheckListName,
  fetchCommentsAPI,
} from "../../services/apiServices";
import AddDueDate from "./AddDueDate";
import AddAttachement from "./AddAttachement";
import AddCover from "./AddCover";
import ClickableDiv from "./ClickableDiv";
import EditableText from "../common/EditableText";
import UserAvatar from "./UserAvatar";
import InviteUsersToCard from "./InviteUsersToCard";
import {
  generateColorFromId,
  getInitials,
  getRandomColor,
} from "../../helpers/func101";

import "./CardDetails.css";
import MemberAvatarList from "../common/MemberAvatarList";
import CardEvents from "./CardEvents";
import CommentList from "./CommentLists";
import EventList from "./EventList";
import { clearEvents } from "../../slices/eventSlice";

export default function CardDetails({ show, onHide, id, list_id }) {


  const dispatch = useDispatch();
  // const { user: currentUser } = useSelector((state) => state.auth);
  // console.log("user",currentUser);
  const card = useSelector((state) => state.cards.cards[0]); //find((card) => card.id === id));
  // console.log("state", card);


  const [comment, setComment] = useState("");
  const [description, setDescription] = useState("");
  

  const [completedPercentage, setCompletedPercentage] = useState(0); //for progress bar
  const [showActivitesOfCards, setShowActivitesOfCards] = useState(false); //for progress bar

  useEffect(() => {
      // console.log('card',card);
    // Calculate the percentage of completed checklist items
    if (card && card.checklists) {
      const totalChecklistItems = card.checklists.length;
      const completedChecklistItems = card.checklists.filter(
        (checklist) => checklist.completed
      ).length;
      const percentage = (completedChecklistItems / totalChecklistItems) * 100;
      setCompletedPercentage(percentage);
      // console.log("caluculate progress bar");
    }
  }, [card]);

  // const getCardById = (card_id) => {
  //   const card = lists.reduce((acc, list) => {
  //     const foundCard = list.cards.find((card) => card.id === card_id);
  //     return acc || foundCard;
  //   }, null);
  //   return card;
  // };
  // console.log(getCardById(id));
  // const [cardInfo, setCardInfo] = useState({});

  // useEffect(() => {

  // }, [id,show] )

  const [showForm, setShowForm] = useState(false);
  const [labelForm, setlabelForm] = useState(false);
  const [checklistForm, setchecklistForm] = useState(false);
  const [dateForm, setdateForm] = useState(false);
  const [attachmentForm, setattachmentForm] = useState(false);
  const [coverForm, setcoverForm] = useState(false);

  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleSubmit = (e) => e.preventDefault();

  const toggleLabelForm = (prev) => setlabelForm(!prev);
  const toggleCheckListForm = (prev) => setchecklistForm(!prev);
  const toggleDateForm = (prev) => setdateForm(!prev);
  const toggleattachmentForm = (prev) => setattachmentForm(!prev);
  const toggleCoverForm = (prev) => setcoverForm(!prev);
  const toggleActivitiesPanel = (prev) => {
    setShowActivitesOfCards(!prev);
    dispatch(clearEvents())
  }

  const handleChecklistToggle = (checklist_id, name, completed) => {
    // console.log("ok");
    updateCheckList({ id: checklist_id, name: name, completed: completed });
    dispatch(fetchCardById(id)); //id of card
    // dispatch(fetchList(boardId));
  };

  const updateTitle = (params, updateTitle) => {
    updateCardTitle(id, updateTitle);
    dispatch(fetchCardById(id));
  };

  const updateLabelName = (params, updateTitle) => {
    updateLabel(params, updateTitle);
    dispatch(fetchCardById(id));
  };

  const updateCheckListName = (params, updateTitle) => {
    // updateCheckList(params, updateTitle);
    updateCheckList({ id: params, name: updateTitle });
    dispatch(fetchCardById(id));
  };

  // const [description, setDescription] = useState('');
  // if (card) {
  //   if (card.description) {
  //     setDescription();
  //   } else {
  //     setDescription("add description here");
  //   }
  // }

  const updateDescription = (params, updateTitle) => {
    addDescription(id, updateTitle);
    // setDescription(updateTitle)
    dispatch(fetchCardById(id));

  };

  const addComment = (params, updateTitle) => {
    setComment("");
    addCommentAPI(id, updateTitle);
    dispatch(fetchCardById(id));

  };

  const handleContentChange = (newContent) => {
    setComment(newContent);
    setDescription(newContent);

    //send to api
  };

  //api delete
  const deleteLabel = (id) => {};

  return (
    card && (
      <Modal className="card-details" show={show} onHide={onHide} centered>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: card.cover,
          }}
        >
          <Modal.Title>
            <EditableText
              title={card.title}
              apiMethod={updateTitle}
              apiParams={card.id}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 left-side">
                <div className="members">
                  <UsersFour size={32} className="card-icon" />
                  <span>Members</span>
                  <MemberAvatarList members={card.members} avatarWidth={40} />
                </div>
                <hr />
                {/* due date */}
                {card.due_date && (
                  <div className="mb-3 due-date">
                    <div className="dates">Dates</div>
                    <div>{card.due_date}</div>
                  </div>
                )}

                {/* Labels */}
                <div className="Labels">
                  <TagSimple size={32} className="card-icon" />
                  <span>Labels</span>

                  <div className="mb-3">
                    {card.labels &&
                      card.labels.map((label) => (
                        <span
                          className="label-item"
                          key={label.id}
                          style={{
                            backgroundColor: label.color,
                          }}
                        >
                          <EditableText
                            title={label.name}
                            apiMethod={updateLabelName}
                            apiParams={label.id}
                          />
                          <div className="delete-label">
                            <X onClick={deleteLabel(label.id)} size={24} />
                          </div>
                        </span>
                      ))}
                  </div>
                </div>

                <hr />
                {/* Description */}
                <div className="mb-3">
                  <TextAlignLeft size={32} className="card-icon" />
                  <span>Description</span>
                  
                  <EditableText
                    title={card.description}                   
                    apiMethod={updateDescription}
                    apiParams={card.id}
                    type="textarea"
                    className={card.description?"":"add-Description"}
                    // 
                    />
                </div>
                {/* progressbar */}
                {/*  */}
                {card.checklists && card.checklists.length > 0 && (
                  <>
                    <ListChecks size={32} className="card-icon" />
                    <span>Checklists</span>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${completedPercentage}%`,
                          backgroundColor:
                            completedPercentage == 100 ? "green" : "",
                        }}
                        aria-valuenow={completedPercentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {`${completedPercentage.toFixed(0)}%`}
                      </div>
                    </div>

                    <div className="mb-3">
                      {card.checklists.map((checklist) => (
                        <Form.Check
                          key={checklist.id}
                          type="checkbox"
                          label={
                            <span
                              style={{
                                textDecoration: checklist.completed
                                  ? "line-through"
                                  : "none",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleChecklistToggle(
                                  checklist.id,
                                  checklist.name,
                                  !checklist.completed
                                )
                              }
                            >
                              <EditableText
                                title={checklist.name}
                                apiMethod={updateCheckListName}
                                apiParams={checklist.id}
                              />
                            </span>
                          }
                          checked={checklist.completed} // Assuming each checklist item has an 'isChecked' property
                          onChange={(e) =>
                            handleChecklistToggle(
                              checklist.id,
                              checklist.name,
                              e.target.checked
                            )
                          }
                        />
                      ))}
                    </div>
                  </>
                )}

                {/* Attachments  */}
                <hr />
                <div className="mb-3">
                  <Paperclip size={32} className="card-icon" />
                  <span>Attachments</span>
                  {card.attachments &&
                    card.attachments.map((attachment) => (
                      <ClickableDiv
                      key={attachment.id}
                        text={attachment.display_text}
                        url={attachment.url}
                      />
                    ))}
                </div>

                <hr />

                {/* Activity */}
                <HourglassMedium size={32} className="card-icon" />
                <span>Activity</span>
                <div className="row">
                  <ActionBtn
                    title="show activities"
                    onClick={() => toggleActivitiesPanel(showActivitesOfCards)}
                  />
                  {/* {showActivitesOfCards && <CardEvents events={card.events}/>} */}
                  {showActivitesOfCards && <EventList cardId={id} />}
                </div>
                <hr />
                {/* Comments */}
                <div className="mb-3">
                  <ChatText size={32} className="card-icon" />
                  <span>Comments </span>

                 
                  {/* {card.comments &&
                    card.comments.map((comment) => (
                      <div key={comment.id} className="comment">
                        <UserAvatar
                          className="comment-avatar"
                          firstName={comment.user.name}
                          lastName={comment.user.name}
                        />
                        <div
                          className="comment-content"
                          dangerouslySetInnerHTML={{ __html: comment.content }}
                        />
                      </div>
                    ))} */}
                  <CommentList CardId={id} />
                  <EditableText
                    title={comment}
                    apiMethod={addComment}
                    apiParams={card.id}
                    type="textarea"
                    className="add-Comment"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <b>Add to card</b>
                <div className="d-flex flex-column align-items-center justify-content-between row">
                  <ActionBtn
                    title="members"
                    Icon={Users}
                    onClick={() => setShowInviteModal(true)}
                  />
                  {showInviteModal && (
                    <InviteUsersToCard
                      cardId={id}
                      onClose={() => setShowInviteModal(false)}
                    /> // Pass the actual card ID here
                  )}

                  <ActionBtn
                    title="Labels"
                    Icon={Sticker}
                    onClick={() => toggleLabelForm(labelForm)}
                  />
                  {/* bug */}
                  {labelForm && (
                    <AddLabel
                      id={id}
                      list_id={list_id}
                      setlabelForm={setlabelForm}
                    />
                  )}

                  <ActionBtn
                    title="Ckecklists"
                    Icon={ListChecks}
                    onClick={() => toggleCheckListForm(checklistForm)}
                  />
                  {checklistForm && <AddCheckList id={id} list_id={list_id} />}

                  <ActionBtn
                    title="Dates"
                    Icon={Calendar}
                    onClick={() => toggleDateForm(dateForm)}
                  />
                  {dateForm && <AddDueDate id={id} list_id={list_id} />}

                  <ActionBtn
                    title="Attachment"
                    Icon={Paperclip}
                    onClick={() => toggleattachmentForm(attachmentForm)}
                  />
                  {attachmentForm && (
                    <AddAttachement id={id} list_id={list_id} />
                  )}

                  <ActionBtn
                    title="Cover"
                    Icon={PaintBrushBroad}
                    onClick={() => toggleCoverForm(coverForm)}
                  />
                  {coverForm && <AddCover id={id} list_id={list_id} />}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
}
