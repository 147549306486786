const production = true;

export const isDebugging = !production;

export let API_BASE_URL;
if (!production) API_BASE_URL = "http://127.0.0.1:8000/api";
else API_BASE_URL = "https://api.is.vr-division.com/api";
// else API_BASE_URL = "https://trello-clone-vrdivision-api.ohoudtravel.com/api";

export const colors = [
  // "rgba(255, 99, 132, 0.6)",
  // "rgba(54, 162, 235, 0.6)",
  // "rgba(255, 206, 86, 0.6)",
  "rgba(75, 192, 192, 0.6)",
  // "rgba(153, 102, 255, 0.6)",
  // "rgba(255, 159, 64, 0.6)",
  "rgba(128, 128, 128, 0.6)",
];

export const REACT_APP_CALENDLY_API_KEY =
  "p8sMo5QDOhf2VbeYXpy4s4uVpp3P2aHIk7rBNZhXJ2Y";

export const space_status = [
  { id: 1, name: "On Going" },
  { id: 2, name: "Done" },
  { id: 3, name: "Interapted" },
  { id: 4, name: "On Hold" },
  { id: 5, name: "In Testing" },
  { id: 6, name: "Waiting for approval" },
  { id: 7, name: "Canceled" },
];
