import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import "./Layout.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const initialSidebarStatus = JSON.parse(
    localStorage.getItem("sidebarStatus")
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    initialSidebarStatus !== null ? initialSidebarStatus : true
  );

  const handleSidebarStatusChange = (status) => {
    setIsSidebarOpen(status);
  };

  const { isLoggedIn } = useSelector((state) => state.auth);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, []);

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    localStorage.setItem("sidebarStatus", JSON.stringify(isSidebarOpen));
  }, [isSidebarOpen]);

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <Sidebar
          isSidebarOpen_={isSidebarOpen}
          onSidebarStatusChange={handleSidebarStatusChange}
        />
        <div
          className={`main-content ${
            isSidebarOpen ? "full-width" : "collapss"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
