// apiService.js
import axios from "axios";
import { API_BASE_URL } from "../config";
import store from "../store";

const user = JSON.parse(localStorage.getItem("user"));
const token = user && user.token;

// Create an instance of Axios with default headers
export const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : "", // Add the Bearer token to headers
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.user ? state.auth.user.token : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API methods
export const fetchAllWorkspaces = async () => {
  const response = await apiInstance.get("/workspaces");
  return response.data;
};

export const createNewWorkspace = async (workspaceData) => {
  const response = await apiInstance.post("/workspaces", workspaceData);
  return response.data;
};

export const updateWorkspaceById = async (id , workspaceData) => {
  const response = await apiInstance.put(
    `/workspaces/${id}`,
    workspaceData
  );
  return response.data;
};


export const deleteWorkspaceById = async (workspaceId) => {
  await apiInstance.delete(`/workspaces/${workspaceId}`);
  return workspaceId;
};
//spaces---------------------------------------
export const updateSpaceById = async (id , spaceData) => {
  const response = await apiInstance.put(
    `/spaces/${id}`,
    spaceData
  );
  return response.data;
};

//boards---------------------------------------
export const updateBoardById = async (id , boardData) => {
  const response = await apiInstance.put(
    `/boards/${id}`,
    boardData
  );
  return response.data;
};
// --------------------------------------- card details---------------------------------------
export const getCardInfo = async (id) => {
  try {
    const response = await apiInstance.get(`/cards/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching info:", error);
    throw error;
  }
};

// Function to add a description to a card
export const addDescription = async (id, description) => {
  try {
    const response = await apiInstance.post(`/cards/${id}/add-description`, {
      description,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding description:", error);
    throw error;
  }
};

// Function to add a label to a card
export const addLabel = async (id, name, color) => {
  try {
    const response = await apiInstance.post(`/cards/${id}/add-label`, {
      name: name,
      color: color,
    });

    return response.data;
  } catch (error) {
    console.error("Error adding label:", error);
    throw error;
  }
};

// Function to add a checklist to a card
export const addChecklist = async (id, name) => {
  try {
    const response = await apiInstance.post(`/cards/${id}/add-checklist`, {
      name: name,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding checklist:", error);
    throw error;
  }
};

// Function to add a comment to a card
export const addCommentAPI = async (id, content) => {
  try {
    const response = await apiInstance.post(`/cards/${id}/add-comment`, {
      content,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

export const editCommentAPI = async (id, content) => {
  try {
    const response = await apiInstance.put(`/editComment/${id}`, {
      content,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

export const deleteCommentAPI = async (id) => {
  try {
    const response = await apiInstance.delete(`/deleteComment/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

 export const addReplyToCommentAPI = async (commentId, content) => {
  try {
    const response = await apiInstance.post(`/comments/${commentId}/replies`, {
      content: content,
    });
    const newReply = response.data;
    return newReply; 
  } catch (error) {
    console.error("Error adding reply:", error);
    throw error;
  }
};

export const fetchCommentsAPI = async (CardId, page) => {
  try {
    const response = await apiInstance.get(`/comments/${CardId}?page=${page}`);
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching comments:", error);
  }
};

// Function to add a due date to a card
export const addDueDate = async (id, start_date, due_date) => {
  //bug
  try {
    const response = await apiInstance.put(`/cards/${id}/add-due-date`, {
      start_date: start_date,
      due_date: due_date,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding due date:", error);
    throw error;
  }
};

// Function to add an attachment to a card
export const addAttachment = async (id, url, display_text) => {
  try {
    const response = await apiInstance.post(`/cards/${id}/add-attachment`, {
      url: url,
      display_text: display_text,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding attachment:", error);
    throw error;
  }
};

// Function to add a cover to a card
export const addCover = async (id, cover) => {
  try {
    const response = await apiInstance.put(`/cards/${id}/add-cover`, {
      cover: cover,
    });
    return response.data;
  } catch (error) {
    console.error("Error adding cover:", error);
    throw error;
  }
};

export const updateCheckList = async (checkListData) => {
  const response = await apiInstance.put(
    `/cards/checklists/${checkListData.id}`,
    checkListData
  );
  return response.data;
};
//updateLabel
export const updateLabel = async (id, name) => {
  const response = await apiInstance.put(`/labels/${id}`, { name: name });
  return response.data;
};

export const updateCardTitle = async (id, title) => {
  const response = await apiInstance.put(`/cards/${id}/edit-name`, {
    title: title,
  });
  return response.data;
};

export const updateListTitle = async (id, title, board_id) => {
  const response = await apiInstance.put(`/lists/${id}`, {
    title: title,
    board_id: board_id,
  });
  return response.data;
};

// export default apiService;
