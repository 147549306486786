import React, { useState } from "react";
import { Button } from "react-bootstrap";
import apiInstance from "../../api";
import { Lightning, LightningSlash } from "phosphor-react";

const AdminButton = ({ text, variant, loading, success, onClick }) => {
  let buttonText = loading
    ? "Loading..."
    : success
    ? "User is now an Admin"
    : text;

  return (
    <Button
      variant={variant}
      size={32}
      onClick={onClick}
      disabled={loading || success}
    >
      {buttonText}
    </Button>
  );
};

const MakeUserAdmin = ({ userId, userType }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleMakeAdmin = async () => {
    setLoading(true);
    setError(null);

    try {
      await apiInstance.put(`/users/${userId}/make-admin`);
      setSuccess(true);
    } catch (error) {
      setError("An error occurred while making the user an admin.");
    } finally {
      setLoading(false);
    }
  };

  return userType ? (
    <>
      <AdminButton
        text="Make as User"
        variant="success"
        loading={loading}
        success={success}
        onClick={handleMakeAdmin}
      />
    </>
  ) : (
    <>
      <AdminButton
        text="Make as Admin"
        variant="danger"
        loading={loading}
        success={success}
        onClick={handleMakeAdmin}
      />
      {error && <p className="text-danger mt-2">{error}</p>}
    </>
  );
};

export default MakeUserAdmin;
