import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCardById, inviteUsersToCard } from "../../slices/cardSlice"; // Replace with your card slice import
import apiInstance from "../../api"; // Replace with your API instance
import Select from "react-select";
import { fetchList } from "../../slices/listSlice";
import { useParams } from "react-router-dom";

const InviteUsersToCard = ({ cardId, onClose }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const dispatch = useDispatch();
  const { boardId } = useParams();
  useEffect(() => {
    // Fetch user options from the API
    const fetchUserOptions = async () => {
      try {
        const response = await apiInstance.get("/getUsersEmails"); // Replace with your API endpoint to fetch user options
        setUserOptions(response.data);
      } catch (error) {
        console.error("Error fetching user options:", error);
      }
    };

    fetchUserOptions();
  }, []);

  const handleUserSelect = (selected) => {
    setSelectedUsers(selected);
  };

  const handleInviteClick = async () => {
    try {
      const userIds = selectedUsers.map((user) => user.id);
      console.log(cardId, selectedUsers);
      const response = await dispatch(inviteUsersToCard({ cardId, userIds }));

      // Dispatch fetch card action if needed
      // dispatch(fetchCard(cardId)); // Replace with your fetch card action
      dispatch(fetchCardById(cardId));
      dispatch(fetchList(boardId));

    //   console.log("Invitation sent:", response);
      onClose(); // Close the component after invitation
    } catch (error) {
      console.error("Error inviting users:", error);
    }
  };

  return (
    <div className="invite-users-modal">
      <b>Invite Users to Card</b>
      <label>Select Users:</label>
      <Select
        options={userOptions}
        isMulti
        value={selectedUsers}
        onChange={handleUserSelect}
        getOptionLabel={(option) => option.email}
        getOptionValue={(option) => option.id}
      />
      <div className="button-group">
        <button className="invite-button" onClick={handleInviteClick}>
          Invite
        </button>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default InviteUsersToCard;
