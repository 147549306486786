import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserDataComponent = () => {
  const clientId = 'OW1W9UYpxB5KMIq-BIOHhtgt9VhfOW9ft9kKheDICBg';
  const clientSecret = 'QQKG2kYngg5b_8hmj9eu21OpLQI_W2dGlk7XzPPGois';

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.post(
        'https://auth.calendly.com/oauth/token',
        `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const accessToken = response.data.access_token;

      const userDataResponse = await axios.get(
        'https://api.calendly.com/users/me',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setUserData(userDataResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>User Data</h1>
      <pre>{JSON.stringify(userData, null, 2)}</pre>
    </div>
  );
};

export default UserDataComponent;
