import React, { useEffect, useState } from "react";
import "./SpaceItem.css";
import { SignIn, Star } from "phosphor-react";
import { generateColorFromId } from "../../helpers/func101";
import EditablePencil from "../common/EditablePencil";
import { updateSpaceById } from "../../services/apiServices";
import { useNavigate } from "react-router-dom";

const SpaceItem = ({
  id,
  background,
  spaceName,
  icon = true,
  onClick,
  desc,
}) => {
  const [title, setTitle] = useState(spaceName);
  const updateSpaceTitle = (params, updateTitle) => {
    updateSpaceById(params, { title: updateTitle });
    setTitle(updateTitle);
    // dispatch(fetchBoardsData());
  };

  const navigate = useNavigate();

  return (
    <div className="col-sm-3  board-card text-center my-2 " key={id}>
      <div  className="card-img-top"
        style={{
          background: `${
            background === undefined ? generateColorFromId(id) : background
          }`, // Replace with the image URL
          backgroundSize: "cover",
          height: "80px",
          position: "relative",
          color:"white",
        }}
      >

        <EditablePencil
          title={title}
          apiMethod={updateSpaceTitle}
          apiParams={id}
          canEdit={true}
        />
        <div   onClick={onClick} className="space_item">
          {/* <SignIn className="navigate_to_space_page" size={44} onClick={() => navigate(`${id}/`)}/> */}
          {/* {spaceName} */}
          <span>{desc}</span>
        </div>

        <div className="space_star">
          {icon && <Star size={32} />}
        </div>
      </div>
    </div>
  );
};

export default SpaceItem;
