import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import "./Space.css";
import apiInstance from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { space_status } from "../config";
import { PlusCircle } from "phosphor-react";
import CreateBoardModal from "../components/Board/CreateBoardModal";
import { fetchBoards } from "../slices/boardSlice";
import { useDispatch } from "react-redux";
import StatusSelector from "../components/Board/StatusSelector";
import Breadcrumb from "../common/Breadcrumb";
import EditablePencil from "../components/common/EditablePencil";
import { updateBoardById } from "../services/apiServices";

const Space = () => {
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [groupField, setGroupField] = useState("status");

  const state = useSelector((state) => state);
  // console.log(state);

  const { spaceId } = useParams();
  const dispatch = useDispatch();

  const fetchBoardsData = async () => {
    try {
      // Dispatch the fetchBoards action
      const response = await dispatch(
        fetchBoards({
          spaceId,
          search: search,
          sortField: sortField,
          sortOrder: sortOrder, // or 'desc'
          groupField: groupField,
        })
      );

      // response.payload contains the fetched data
      // setBoards(response.payload)
      // console.log('Fetched boards:', response.payload);
    } catch (error) {
      console.error("Error fetching boards:", error);
    }
  };

  const boards = useSelector((state) => state.boards.boards);

  useEffect(() => {
    fetchBoardsData();
    // if (JSON.stringify(boards) === JSON.stringify({ "On Going": undefined })) {
    //   boards = []
    // }
  }, [spaceId]);

  useEffect(() => {
    fetchBoardsData();
  }, [search, sortField, sortOrder, groupField, dispatch]);

  // const fetchBoards = async (id) => {
  //   try {
  //     const response = await apiInstance.get(`/spaces/${id}/boards`, {
  //       params: { search, sortField, sortOrder, groupField },
  //     });
  //     //   console.log("====================================");
  //     //   console.log(response.data);
  //     //   console.log("====================================");

  //     setBoards(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSortChange = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handleGroupChange = (field) => {
    setGroupField(field);
  };

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleStatusChange = async () => {
    toggleModal();

    try {
      await apiInstance.put(`/boards/${boardId}/change_status`, {
        status: newStatus,
      });
      // After changing the status, you can refresh the boards list
      fetchBoardsData();
    } catch (error) {
      console.error(error);
    }
  };

  const [boardId, setBoardId] = useState();
  const [newStatus, setNewStatus] = useState();
  const handleConfirmChangeStatus = (boardId, newStatus) => {
    toggleModal();
    setBoardId(boardId);
    setNewStatus(newStatus);
  };

  const [showCreateBoardModal, setShowCreateBoardModal] = useState(false);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const url = window.location.href;
    const ids = url.split("/").slice(3);
    //  console.log("state",state);
    const currentWorkspace = Object(
      state?.workspaces?.data?.find((w) => w.id === parseInt(ids[1]))
    );
    //  console.log("currentWorkspace",currentWorkspace);
    const currentSpace = Object(
      currentWorkspace?.spaces?.find((w) => w.id === parseInt(ids[2]))
    );
    // console.log("currentSpace", currentSpace);
    setBreadcrumbs([
      { label: "workspaces", link: "/w" },
      { label: currentWorkspace?.name, link: `/w/${currentWorkspace?.id}` },
      {
        label: currentSpace?.title,
        link: `/w/${currentWorkspace?.id}/${currentSpace?.id}`,
      },
    ]);
  }, [spaceId]);

  const updateBoardTitle = (params, updateTitle) => {
    updateBoardById(params, { boardName: updateTitle });
    dispatch(fetchBoardsData());
  };

  const navigate = useNavigate();

  return (
    <div className="container mt-4">
      <div className="header_breadcrumb">
        <Breadcrumb items={breadcrumbs} />
        <Button
          variant="success"
          className="shiny_btn mr-2"
          onClick={() => setShowCreateBoardModal(true)}
        >
          <PlusCircle size={22} />
        </Button>
      </div>

      <div className="space-header m-2">
        <h4>Boards</h4>
      </div>
      {/* Add search and controls */}
      <div className="row mb-3">
        <div className="col-sm-6">
          <Form.Control
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <div className="col-sm-6">
          <Form.Control
            as="select"
            value={sortField}
            onChange={(e) => handleSortChange(e.target.value)}
          >
            <option value="id">Sort by ID</option>
            <option value="boardName">Sort by Name</option>
          </Form.Control>
        </div>
      </div>
      {/* Map through boards */}
      <div className="row">
        {Object.keys(boards) &&
          Object.keys(boards).map((status, index) => (
            <div key={index} className="col-sm-2">
              <div className="status-section">
                <h2 className="status-title">{status}</h2>
                <ul className="board-items">
                  {boards[status] &&
                    boards[status].map((board, boardIndex) => (
                      <div className="board-div" key={boardIndex}>
                        {/* renaming board  */}
                        {/* <Pencil
                          className="space_edit_name"
                          size={24}
                          onClick={handlePencilClick}
                        /> */}
                        <EditablePencil
                          title={board.boardName}
                          apiMethod={updateBoardTitle}
                          apiParams={board.id}
                          canEdit={true}
                        />
                        <div
                          onClick={() => navigate(`board/${board.id}`)}
                          className="go_to_this_board"
                        ></div>

                        <span className="change-status">
                          <StatusSelector
                            key={board.id}
                            board={board}
                            handleConfirmChangeStatus={
                              handleConfirmChangeStatus
                            }
                            space_status={space_status}
                          />
                        </span>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to change the status?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleStatusChange()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <CreateBoardModal
        show={showCreateBoardModal}
        onClose={() => setShowCreateBoardModal(false)}
      />
    </div>
  );
};

export default Space;
