// ToggleComponent.js
import React, { useState } from "react";
import "./Board.css";
import Board_View1 from "./Board_View1";
import Board_View2 from "./Board_View2";

const Board = () => {
  const [activeButton, setActiveButton] = useState(1);

  const showComponent1 = activeButton === 1;

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  return (
    <div className="toggle-component">
      <div className="toggle-buttons">
        <button
          className={`toggle-button ${activeButton === 1 ? "active" : ""}`}
          onClick={() => handleButtonClick(1)}
        >
          List View
        </button>
        <button
          className={`toggle-button ${activeButton === 2 ? "active" : ""}`}
          onClick={() => handleButtonClick(2)}
        >
          Kanban View
        </button>
      </div>
      {/* Board_View1 = Kanban  */}
      {showComponent1 ? <Board_View2 /> : <Board_View1 />}
    </div>
  );
};

export default Board;
