import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchCardById, clearCards } from "../../slices/cardSlice";

import CardDetails from "./CardDetails";
import "./TrelloCard.css";
import { CheckSquareOffset } from "phosphor-react";
import MemberAvatarList from "../common/MemberAvatarList";
import { clearEvents } from "../../slices/eventSlice";

function TrelloCard({ className, id, text, list_id, card , showDetailsOfCard }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    // dispatch(clearEvents());//bug 111
    setShowModal(true);
    dispatch(clearCards());
    dispatch(fetchCardById(id));
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const [completedChecklistsCount, setCompletedChecklistsCount] = useState(0);

  // count of completed checklist items
  useEffect(() => {
    if (card && card.checklists) {
      const completedChecklistItems = card.checklists.filter(
        (checklist) => checklist.completed
      ).length;
      setCompletedChecklistsCount(completedChecklistItems);
    }
    // console.log("card", card);

  }, []);



  // useEffect(() => {
  //   if (showDetailsOfCard) {
  //     handleShowModal();
  //     console.log("showDetailsOfCard",showDetailsOfCard);
  //   }
  // }, [showDetailsOfCard])
  
 

  return (
    <div>
      <div
        className={className + " card_item"}
        onClick={handleShowModal}
        style={{
          height: "fit-content",
        }}
      >
        <div
          className="card-cover"
          style={{
            backgroundColor: card.cover,
            height: card.cover != null ? 10 : 0,
          }}
        ></div>
        {/* <input name="card_id" type="hidden" value={id} /> */}
        <div>
          {card.labels &&
            card.labels.map((label) => (
              <span
                key={label.id}
                style={{
                  backgroundColor: label.color,
                  padding: 2,
                  margin: 3,
                  borderRadius: 12,
                  fontSize: 12,
                  color: "white",
                  display: "inline-block",
                }}
              >
                {label.name}
              </span>
            ))}
        </div>
        {card.title}
        {(card.checklists.length > 0 || card.users) && (
          <div className="card-task-footer">
            {card.checklists.length > 0 && (
              <div className="checklists">
                <CheckSquareOffset />{" "}
                <span className="checklists-completed-count">
                  {completedChecklistsCount + "/" + card.checklists.length}
                </span>
              </div>
            )}
            <MemberAvatarList members={card.users} avatarWidth={25} />
          </div>
        )}
      </div>

      {/* modal card */}
      <CardDetails
        show={showModal}
        onHide={handleHideModal}
        id={id}
        list_id={list_id}
      />
    </div>
  );
}

export default TrelloCard;
