import React from 'react';
// import './MemberAvatarList.css'


function generateColorFromId(id) {
  const hue = (id * 37) % 360;
  return `hsl(${hue}, 70%, 50%)`;
}

function getInitials(name) {
  const parts = name.split(' ');
  return parts.map((part) => part[0]).join('').toUpperCase();
}




const MemberAvatarList = ({ members, avatarWidth }) => {


    const memberAvatarStyle = {
        borderRadius: '50%',
        margin: '2px',
        padding: '5px',
        color: 'white',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: avatarWidth,
        height:avatarWidth,
        backgroundColor: '#5c5c5c',
        fontWeight: 'bold',
        fontSize: avatarWidth/3,
        textTransform: 'uppercase',
      };

  return (
    <div className="member-container">
      {members &&  members.map((member, index) => (
        <span
        //   className="member-avatar"
          key={index}
          style={{
            ...memberAvatarStyle,
            backgroundColor: generateColorFromId(member.id),
          }}
          title={`${member.name} | ${member.email}`}
        >
          {getInitials(member.name)}
        </span>
      ))}
    </div>
  );
};

export default MemberAvatarList;
