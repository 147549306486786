import React from "react";
import { Paperclip } from "phosphor-react";
import './ClickableDiv.css'


const ClickableDiv = ({ text, url }) => {
  const handleDivClick = () => {
    window.open(url, "_blank"); // Open URL in a new tab
    // window.location.href = url;

  };

  return (
    <div
      onClick={handleDivClick}
      style={{ cursor: "pointer" }}
      className="attachment-item d-flex justify-content-between col-sm-10"
    >
      <Paperclip size={22} />
      {text}
    </div>
  );
};

export default ClickableDiv;
