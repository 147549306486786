import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, googleProvider } from "../firebaseConfig";

 

import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));


export const register = createAsyncThunk(
  "auth/register",
  async ({ name, email, password, c_password }, thunkAPI) => {
    try {
      const response = await AuthService.register(
        name,
        email,
        password,
        c_password
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      // console.log("register",response.data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      // console.log(data.data.user);
 
        return { user: data.data.user };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  console.log("logout");
  await AuthService.logout();
});

export const loginWithGoogle = createAsyncThunk(
  "auth/login",
  async (_, thunkAPI) => {
    try {
      // Sign in with Google
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      const user = result.user;

      // Check if the user is already registered
      const response = await AuthService.register(
        user.displayName,
        user.email,
        user.uid,//password
        user.uid,//c_password
        user.photoURL
      );
      // console.log("after register", response.status);

      const data = await AuthService.login(user.email, user.uid);
      // console.log(data.data.user);
 

      return { user: data.data.user };

      // // Serialize the user data
      // const serializedUser = {
      //   uid: user.uid,
      //   displayName: user.displayName,
      //   email: user.email,
      //   img: user.photoURL
      // };
    } catch (error) {
      // ... (error handling as before)
    }
  }
);

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers:{
    loginFromHRSystem: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      // console.log(' action.payload', action.payload);
    },
    // clear:(state)=>{
    //   state.isLoggedIn = false;
    //   state.user = null;
    // }
  },
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      // setMessage(JSON.stringify(action.payload))
      // alert(1)
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
      // alert(2)
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [loginWithGoogle.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [loginWithGoogle.fulfilled]: (state, action) => {
      //sam
      state.isLoggedIn = true;
      state.user = action.payload.user;
      // alert(1)
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export const { loginFromHRSystem} = authSlice.actions; // Export the action
export default reducer;
