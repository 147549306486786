import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./EditableText.css";
import { Pencil } from "phosphor-react";
import SaveCancelButton from "./SaveCancelButton";

const EditablePencil = ({ title, apiMethod, apiParams, type, className, canEdit }) => {
  const [editing, setEditing] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);

  const handleTitleChange = (e) => {
    if (type === "textarea") setUpdatedTitle(e);
    else setUpdatedTitle(e.target.value);
  };

  const quillRef = useRef(null);

  const handleSave = () => {
    if (updatedTitle !== title) {
      setEditing(false);
      apiMethod(apiParams, updatedTitle);
      setUpdatedTitle("");
    } else {
      setEditing(false);
    }
  };

  const handleCancel = () => {
    setEditing(false);
    setUpdatedTitle(title);
  };

  useEffect(() => {
    if (editing && quillRef.current) {
      // Focus the editor when editing is true
      quillRef.current.focus();
    }
  }, [editing]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.altKey) {
        handleSave();
      } else if (!e.altKey && canEdit) {
        handleSave();
      }
    }
  };

  return (
    <div className="editable">
      {editing && canEdit ? (
        type === "textarea" ? (
          <>
            <ReactQuill
              theme="snow"
              ref={quillRef}
              value={updatedTitle}
              onChange={handleTitleChange}
              onKeyDown={handleKeyPress} // Capture keyboard events
              autoFocus
            />
            <SaveCancelButton onSave={handleSave} onCancel={handleCancel} />
          </>
        ) : (
          <>
            <input
              className={`${className} input-editable`}
              type="text"
              value={updatedTitle}
              onChange={handleTitleChange}
              onKeyDown={handleKeyPress} // Capture keyboard events
              autoFocus
            />
            <SaveCancelButton onSave={handleSave} onCancel={handleCancel} />
          </>
        )
      ) : (
        <div
          onClick={() => canEdit && setEditing(true)}
          className={className}
        >
          <div
            className="div-editable"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {canEdit && (
            <Pencil className="pencil_editable" size={26} onClick={() => setEditing(true)} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditablePencil;
