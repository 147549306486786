import React, { useEffect, useState } from "react";
import TrelloList from "../components/List/TrelloList";
import AddList from "../components/List/AddList";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchList, clearLists, updatePositions } from "../slices/listSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateCardsInList } from "../slices/listSlice";
import Breadcrumb from "../common/Breadcrumb";
import apiInstance from "../api";
import "./Board.css";


function Board_View1() {
  const { boardId } = useParams();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const lists = state.lists.data;

  const [localLists, setLocalLists] = useState([]);

  useEffect(() => {
    dispatch(fetchList(boardId));
  }, [dispatch, boardId]);

  useEffect(() => {
    setLocalLists(lists);
  }, [lists]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    if (
      sourceDroppableId === destinationDroppableId &&
      sourceIndex === destinationIndex
    )
      return;

    //moving List
    if (sourceDroppableId == "board") {
      let reorderedLists;
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      // console.log('from',sourceIndex,"to",destinationIndex);
      if (sourceIndex === destinationIndex) return;

      reorderedLists = Array.from(lists);
      const [removedList] = reorderedLists.splice(sourceIndex, 1);
      reorderedLists.splice(destinationIndex, 0, removedList);

      dispatch(updatePositions(reorderedLists));
      setLocalLists(reorderedLists);
      //  dispatch(fetchList(boardId));
    }
    //moving card
    else {
      const sourceListId = parseInt(sourceDroppableId.replace("List", ""));
      const destinationListId = parseInt(
        destinationDroppableId.replace("List", "")
      );

      if (sourceDroppableId === destinationDroppableId) {
        // Reordering cards in the same list
        const list = lists.find((list) => list.id === sourceListId);
        const reorderedCards = Array.from(list.cards);
        const [removedCard] = reorderedCards.splice(sourceIndex, 1);
        reorderedCards.splice(destinationIndex, 0, removedCard);

        dispatch(
          updateCardsInList({
            listId: sourceListId,
            updatedCards: reorderedCards,
          })
        );
      } else {
        // Moving card to a different list
        const sourceList = lists.find((list) => list.id === sourceListId);
        const destinationList = lists.find(
          (list) => list.id === destinationListId
        );

        const movedCard = sourceList.cards[sourceIndex];

        const updatedSourceList = {
          ...sourceList,
          cards: sourceList.cards.filter((_, index) => index !== sourceIndex),
        };
        const updatedDestinationList = {
          ...destinationList,
          cards: [
            ...destinationList.cards.slice(0, destinationIndex),
            movedCard,
            ...destinationList.cards.slice(destinationIndex),
          ],
        };

        dispatch(
          updateCardsInList({
            listId: sourceListId,
            updatedCards: updatedSourceList.cards,
          })
        );
        dispatch(
          updateCardsInList({
            listId: destinationListId,
            updatedCards: updatedDestinationList.cards,
          })
        );
      }
    }

    dispatch(fetchList(boardId));
  };

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentBoard, setcurrentBoard] = useState('');

  const url = window.location.href;
  const ids = url.split("/").slice(3);
  // console.log("state", state);
  const currentWorkspace = Object(
    state?.workspaces?.data?.find((w) => w.id === parseInt(ids[1]))
  );
  //  console.log("currentWorkspace",currentWorkspace);
  const currentSpace = Object(
    currentWorkspace?.spaces?.find((w) => w.id === parseInt(ids[2]))
  );
  
  const getBoardTitleById = async (id) => {
    const response = await apiInstance.get(`boards/${id}/title`);
    const data = response.data;
    setcurrentBoard(data.title);

    // Update breadcrumbs here
    setBreadcrumbs([
      { label: "workspaces", link: "/w" },
      { label: currentWorkspace?.name, link: `/w/${currentWorkspace?.id}` },
      {
        label: currentSpace?.title,
        link: `/w/${currentWorkspace?.id}/${currentSpace?.id}`,
      },
      {
        label: data.title, // Use the fetched board title
        link: `/w/${currentWorkspace?.id}/${currentSpace?.id}/board/${boardId}`,
      },
    ]);
  };

 

  useEffect(() => {
   
    //  console.log("currentSpace",currentSpace);
    getBoardTitleById(boardId);

    // setBreadcrumbs([
    //   { label: "workspaces", link: "/w" },
    //   { label: currentWorkspace?.name, link: `/w/${currentWorkspace?.id}` },
    //   {
    //     label: currentSpace?.title,
    //     link: `/w/${currentWorkspace?.id}/${currentSpace?.id}`,
    //   },
    //   {
    //     label: currentBoard,
    //     link: `/w/${currentWorkspace?.id}/${currentSpace?.id}/board/${boardId}`,
    //   },
    // ]);
  }, []);

  return (
    <div> 
      {/* <div className="mini-nav">
      </div> */}
      <Breadcrumb items={breadcrumbs} />
      <div className="disable-text-selection">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="board-container"
              >
                {localLists && // Use lists directly from the Redux store
                  localLists.map((list, index) => (
                    <div className="pos_div" key={list.id}>
                      <Draggable draggableId={"List" + list.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TrelloList
                              className={` ${
                                snapshot.isDragging ? "dragged-item" : ""
                              }`}
                              key={list.id}
                              id={list.id}
                              // list.id + " " + debugging
                              title={list.title} 
                              cards={list.cards}
                            />
                          </div>
                        )}
                      </Draggable>
                    </div>
                  ))}
                {provided.placeholder}
                <AddList />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default Board_View1;
