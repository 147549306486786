import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, ListGroup , Modal } from "react-bootstrap";
import { apiInstance } from "../../services/apiServices";
import Select from "react-select";
import toast from "react-hot-toast";
import "./TaskPermissions_remove.css";

function TaskPermissions_remove({ users }) {
  const [selectedUser, setSelectedUser] = useState("");
  const [workspaces, setWorkspaces] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [boards, setBoards] = useState([]);
  const [cards, setCards] = useState([]);

  const handleUserChange = (selectedOptions) => {
    // alert(selectedOptions)
    // console.log(selectedOptions.value);
    setSelectedUser(selectedOptions); //{value: , name:}
  };

  const fetchUserData = async () => {
    if (!selectedUser) {
      return;
    }

    //fetch user permissions from backend
    try {
      let userPermissions = await apiInstance.get(
        `/user/${selectedUser.value}/userPermissions`
      );
      userPermissions = userPermissions.data.data;
      // console.log(userPermissions);
      setWorkspaces(userPermissions.workspaces);
      setSpaces(userPermissions.spaces);
      setBoards(userPermissions.boards);
      setCards(userPermissions.cards);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const removeItem = async (itemType, itemId) => {
    try {
      const data = { itemType, itemId }; // Create an object with the payload
      const res = await apiInstance.delete(
        `/user/${selectedUser.value}/removePermissions`,
        { data }
      );
      if (res.data.status) {
        toast.success("Permission removed!");
      }
      // Refresh the data after removal
      fetchUserData();
    } catch (error) {
      toast.error("Some error occurred");
      console.error("Error removing item:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [selectedUser]);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClearPermissions = async () => {
    try {
      await apiInstance.delete("/clearAllPermissionsForAllUsers");
      toast.success("deleted !");
      handleClose();
      fetchUserData();
      console.log("Permissions cleared successfully");
    } catch (error) {
      console.error("Error clearing Permissions:", error);
    }
  };
  //TODOS
  /*
1-show no permissions
2-delete permissions in hierarchy
3-show icon btn to refresh page
4-show text to search for a task
5-show partial / Total access to (w ,s , b , t)
*/

  return (
    <Container>
      <h1>User Workspace Component</h1>
      <button className="btn btn-danger" onClick={handleShow}>
        Clear All Permissions For All Users
      </button>
      <Form.Group>
        <Form.Label>
          Select User
          <Button onClick={fetchUserData}> refresh </Button>
        </Form.Label>
        <Select
          options={users}
          value={selectedUser}
          isMulti={false}
          isSearchable={true}
          onChange={handleUserChange}
        />
      </Form.Group>
      {selectedUser && (
        <Row>
          <Col>
            <h2>Workspaces</h2>
            <ListGroup>
              {workspaces &&
                workspaces.map((workspace) => (
                  <ListGroup.Item key={workspace.id}>
                    {workspace.name}
                    <Button
                      className="remove_this_permission"
                      variant="danger"
                      size="sm"
                      onClick={() => removeItem("workspace", workspace.id)}
                    >
                      Remove
                    </Button>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
          <Col>
            <h2>Spaces</h2>
            <ListGroup>
              {spaces &&
                spaces.map((space) => (
                  <ListGroup.Item key={space.id}>
                    {space.title}
                    <Button
                      className="remove_this_permission"
                      variant="danger"
                      size="sm"
                      onClick={() => removeItem("space", space.id)}
                    >
                      Remove
                    </Button>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
          <Col>
            <h2>Boards</h2>
            <ListGroup>
              {boards &&
                boards.map((board) => (
                  <ListGroup.Item key={board.id}>
                    {board.boardName}
                    <Button
                      className="remove_this_permission"
                      variant="danger"
                      size="sm"
                      onClick={() => removeItem("board", board.id)}
                    >
                      Remove
                    </Button>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
          <Col>
            <h2>Cards</h2>
            <ListGroup>
              {cards &&
                cards.map((card) => (
                  <ListGroup.Item key={card.id}>
                    {card.title}
                    <Button
                      className="remove_this_permission"
                      variant="danger"
                      size="sm"
                      onClick={() => removeItem("card", card.id)}
                    >
                      Remove
                    </Button>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
      )}


<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to clear All Permissions for All Users?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClearPermissions}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default TaskPermissions_remove;
