import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"; // Make sure to import Link and useLocation
import { FaPlus, FaMinus } from "react-icons/fa"; // Import the icons

const SidebarItems = ({ items, type = "" }) => {
  const [activeSubMenus, setActiveSubMenus] = useState([]);
  const location = useLocation(); // Get the current location using useLocation hook

  const handleSubMenuToggle = (id) => {
    if (activeSubMenus.includes(id)) {
      setActiveSubMenus(activeSubMenus.filter((subMenuId) => subMenuId !== id));
    } else {
      setActiveSubMenus([...activeSubMenus, id]);
    }
  };

  return (
    <div>
      <ul>
        {items &&
          items.map((sharedWorkspace, index) =>
            sharedWorkspace.type === type ? (
              <li key={"li" + sharedWorkspace.id}>
                <div
                  className={`sidebar-item ${
                    location.pathname === `/w/${sharedWorkspace.id}` ? "active" : ""
                  }`}
                  onClick={() => handleSubMenuToggle(sharedWorkspace.id)}
                >
                  <div className="icon">
                    {activeSubMenus.includes(sharedWorkspace.id) ? (
                      <FaMinus />
                    ) : (
                      <FaPlus />
                    )}
                  </div>
                  <Link
                    className="sidebar-link"
                    to={`/w/${sharedWorkspace.id}`}
                  >
                    {sharedWorkspace.name}
                  </Link>
                </div>
                <ul
                  className={`sub-menu ${
                    activeSubMenus.includes(sharedWorkspace.id) ? "active" : ""
                  }`}
                >
                  {sharedWorkspace.spaces?.map((item, index) => (
                    <li key={item.title}>
                      <Link
                        className={`sidebar_sub_link ${
                          location.pathname === `/w/${sharedWorkspace.id}/${item.id}`
                            ? "active_item"
                            : ""
                        }`}
                        to={`/w/${sharedWorkspace.id}/${item.id}`}
                      >
                        {index + 1}_ {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ) : null
          )}
      </ul>
    </div>
  );
};

export default SidebarItems;
