import { InputGroup, FormControl, Button } from "react-bootstrap";
import React, { useState } from "react";
import { addChecklist } from "../../services/apiServices";
import { X } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import {fetchList } from "../../slices/listSlice";
import {fetchCardById, fetchCards } from "../../slices/cardSlice";

import { useParams } from "react-router-dom";

function AddLabel({ id, list_id }) {

  const [name, setName] = useState("");
 
 
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const { boardId } = useParams();

  const handleAdd =  () => {
    try {
      addChecklist(id, name); // Assuming addLabel is an asynchronous function
      setName("");
       setShow(false);

      dispatch(fetchCardById(id));
      dispatch(fetchList(boardId));
    } catch (error) {
      console.error("Error adding label:", error);
      // Handle errors...
    }
  };

  return (
    show && (
      <div
        className="row"
        style={{
          position: "absolute",
          top: "160px",
          left: 0,
          width: "fit-content",
          height: "fit-content",
          background: "#ccc",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 999,
          visibility: show ? "" : "hidden",
        }}
      >
        <div className="col-12 m-2">
          <label htmlFor="labelTitle" className="form-label">
            CheckList title:
          </label>
          <FormControl
            id="labelTitle"
            className="form-control"
            placeholder="Label title"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        
        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleAdd}>
            Add checklist
          </Button>
          <Button    variant="secondary" onClick={() => setShow(false)}>
             Close 
          </Button>
         </div>
      </div>
    )
  );
}

export default AddLabel;
