import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "./Board_View2.css";
import { getRandomColor } from "../helpers/func101";
import MemberAvatarList from "../components/common/MemberAvatarList";
import AddCard from "../components/Card/AddCard";
import AddList from "../components/List/AddList";
import { fetchList } from "../slices/listSlice";

function Board_View2() {
  const { boardId } = useParams();
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.lists.data);
  console.log('====================================');
  console.log(lists);
  console.log('====================================');

  useEffect(() => {
    dispatch(fetchList(boardId));
  }, [dispatch, boardId]);

  const getBackgroundColor = (title) =>
    title === "opened"
      ? "blue"
      : title === "closed"
      ? "green"
      : getRandomColor();

  return (
    <div className="list-view">
      {lists.map((list) => (
        <div key={list.id} className="list">
          <table className="card-table">
            <thead>
              <tr>
                <th style={{ width: 400 }}>
                  <span
                    className="list_title_tbl"
                    style={{ backgroundColor: getBackgroundColor(list.title) }}
                  ></span>
                  <span className="list_title_fixed"> {list.title}</span>{" "}
                  <i>{list.cards?.length} Tasks</i>
                </th>
                <th>Users</th>
                <th>Due Date</th>
                <th>Priority</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {list.cards?.map((card) => (
                <tr key={card.title} className="task_card">
                  <td>{card.title}</td>
                  <td>
                    <MemberAvatarList members={card.users} avatarWidth={25} />
                  </td>
                  <td>{card.due_date}</td>
                  <td>{card.priority}</td>
                  <td>{card.progress}%</td>
                </tr>
              ))}
            </tbody>
            <div className="add_new_task">
              <AddCard list__id={list.id} />
            </div>
          </table>
        </div>
      ))}
      <AddList />
    </div>
  );
}

export default Board_View2;
