import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Container, Row, Col, Modal } from 'react-bootstrap';
import apiInstance from '../../api';
import toast from "react-hot-toast";



const WorkspacesCRUD = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);

  useEffect(() => {
    fetchWorkspaces();
  }, [page, searchTerm]);

  const fetchWorkspaces = async () => {
    try {
      const response = await apiInstance.get('/getWorkspacesForAdmin', {
        params: {
          page,
          search: searchTerm,
        },
      });
      if (page === 1) {
        setWorkspaces(response.data.data);
      } else {
        setWorkspaces((prevWorkspaces) => [...prevWorkspaces, ...response.data.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteWorkspace = async () => {
    try {
      if (workspaceToDelete) {
        await apiInstance.delete(`/workspaces/${workspaceToDelete}`);
        setWorkspaces((prevWorkspaces) => prevWorkspaces.filter((workspace) => workspace.id !== workspaceToDelete));
        setShowConfirmation(false);
        toast.success("deleted !")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Container>
      <h2>Workspaces</h2>
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Boards Count</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {workspaces.map((workspace) => (
            <tr key={workspace.id}>
              <td>{workspace.id}</td>
              <td>{workspace.name}</td>
              <td>{workspace.boards_count}</td>
              <td>
                <Button variant="danger" onClick={() => { setShowConfirmation(true); setWorkspaceToDelete(workspace.id); }}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col>
          {workspaces.length > 0 && (
            <Button className="mb-3" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Col>
      </Row>
      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this workspace?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteWorkspace}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default WorkspacesCRUD;
