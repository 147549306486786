// workspaceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
 import apiInstance from '../api';
import store from '../store'

 apiInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.user ? state.auth.user.token : null;

    // const jsonObject = JSON.parse(state.auth.user);
    // const token = state.auth.user ? jsonObject.token : null;
    // console.log('token',token );
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


 export const fetchWorkspaces = createAsyncThunk('workspaces/fetchAll', async () => {
  //  const response = await apiInstance.get('/workspaces');
   const response = await apiInstance.get('/getWorkspacesOfUser');
  // alert(JSON.stringify(response.data))
  return response.data;
});

// Create a thunk for creating a new workspace
export const createWorkspace = createAsyncThunk('workspaces/create', async (workspaceData) => {
  const response = await apiInstance.post('/workspaces', workspaceData);
  return response.data;
});

export const createSpace = createAsyncThunk('workspaces/createSpace', async (spaceData) => {
  const response = await apiInstance.post('/spaces', spaceData);
  return response.data;
});



// Create a thunk for updating an existing workspace
export const updateWorkspace = createAsyncThunk('workspaces/update', async (workspaceData) => {
  const response = await apiInstance.put(`/workspaces/${workspaceData.id}`, workspaceData);
  return response.data;
});

 // Create a thunk for deleting a workspace
export const deleteWorkspace = createAsyncThunk('workspaces/delete', async (workspaceId) => {
  await apiInstance.delete(`/workspaces/${workspaceId}`);
  return workspaceId;
});

//spaces
export const fetchSpaces = createAsyncThunk('workspace/fetchSpaces', async (workspaceId) => {
  try {
    const response = await apiInstance.get(`workspaces/${workspaceId}/spaces`);
    // console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    throw new Error('Error fetching spaces');
  }
});


//bug : conflict happend
//boards
// export const fetchBoards = createAsyncThunk('workspace/fetchBoards', async (spaceId) => {
//   try {
//     const response = await apiInstance.get(`spaces/${spaceId}/boards`);
//     // console.log(response.data.data);
//     return response.data.data;
//   } catch (error) {
//     throw new Error('Error fetching boards');
//   }
// });






// Create the workspace slice
const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState: {
    data: [],
    spaces:[],
    boards: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    // setWorkspaces: (state, action) => {
    //   state.data = action.payload;  
    //   state.status = 'succeeded';
    //   state.error = null;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        // state.spaces = action.payload.spaces;
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createWorkspace.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(updateWorkspace.fulfilled, (state, action) => {
        const updatedWorkspace = action.payload;
        const existingWorkspaceIndex = state.data.findIndex((workspace) => workspace.id === updatedWorkspace.id);
        if (existingWorkspaceIndex !== -1) {
          state.data[existingWorkspaceIndex] = updatedWorkspace;
        }
      })
      .addCase(deleteWorkspace.fulfilled, (state, action) => {
        const deletedWorkspaceId = action.payload;
        state.data = state.data.filter((workspace) => workspace.id !== deletedWorkspaceId);
      })


      //boards
      // .addCase(fetchBoards.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(fetchBoards.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.boards = action.payload;
      // })
      // .addCase(fetchBoards.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message;
      // })

      //spaces
      .addCase(fetchSpaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSpaces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.spaces = action.payload;
      })
      .addCase(fetchSpaces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(createSpace.fulfilled, (state, action) => {
        state.spaces.push(action.payload);
      })
      //cards
  },
});


// export const { setWorkspaces } = workspaceSlice.actions;

export default workspaceSlice.reducer;
