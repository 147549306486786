import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiInstance from "../api";

//update cards between lists , same list
export const updateCardsInList = createAsyncThunk(
  "lists/updateCardsInList",
  async ({ listId, updatedCards }) => {
    try {
      const response = await apiInstance.put(
        `lists/${listId}/cards`,
        updatedCards
      );
      // return { listId, updatedCards: response.data.cards };
      let data = response.data.data;
      return data;
    } catch (error) {
      throw new Error("Error updating cards in list");
    }
  }
);

export const clearLists = createAsyncThunk("lists/clearLists", async () => {
  return null;
});

// Fetch boards for the current workspace
export const fetchList = createAsyncThunk(
  "lists/fetchList",
  async (boardId) => {
    try {
      const response = await apiInstance.get(`board/${boardId}/lists`);
      let data = response.data.data;
      return data;
    } catch (error) {
      throw new Error("Error fetching boards");
    }
  }
);

// Create a new board
export const createList = createAsyncThunk(
  "lists/createList",
  async (listData) => {
    try {
      const response = await apiInstance.post("lists", listData);
      return response.data;
    } catch (error) {
      throw new Error("Error creating board");
    }
  }
);

// Update list positions
export const updatePositions = createAsyncThunk(
  "lists/updatePositions",
  async (lists) => {
    // console.log("sending to API:",lists);
    try {
      const response = await apiInstance.put("lists/update-positions", {
        lists,
      });
      // console.log("fetched", response.data.data);
      return response.data.data;
    } catch (error) {
      throw new Error("Error updating positions");
    }
  }
);

const initialState = {
  data: [], // Array to store boards
  status: "idle", // Loading status for async actions
  error: null, // Error message if an error occurs
};

const listSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    // Add any additional synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data.push(action.payload.data); // Update the boards array with the newly created board
      })
      .addCase(createList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePositions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePositions.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the lists array with the new positions
        state.data = action.payload;
      })
      .addCase(updatePositions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(clearLists.fulfilled, (state) => {
        //sam
        state.data = [];
      });

    //update positions of cards
    // .addCase(updateCardsInList.pending, (state) => {
    //   state.status = 'loading';
    // })
    // .addCase(updateCardsInList.fulfilled, (state, action) => {
    //   state.status = 'succeeded';
    // state.data = state.data.map((list) =>
    //   list.id === action.payload.listId
    //     ? { ...list, cards: action.payload.updatedCards }
    //     : list
    // );
    //    state.data = action.payload;
    // })
    // .addCase(updateCardsInList.rejected, (state, action) => {
    //   state.status = 'failed';
    //   state.error = action.error.message;
    // });
  },
});

export default listSlice.reducer;
