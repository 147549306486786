import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiInstance from "../api";

export const fetchBoards = createAsyncThunk(
  "board/fetchBoards",
  async ({ spaceId, search, sortField, sortOrder, groupField }) => {
    try {
      const response = await apiInstance.get(`spaces/${spaceId}/boards`, {
        params: { search, sortField, sortOrder, groupField },
      });

      // console.log('boards = ', response.data);
      
      
      //re Order data
      let responseData = response.data;
      const onGoingBoards = responseData["On Going"];
      delete responseData["On Going"];

      const reorderedData = {
        "On Going": onGoingBoards,
        ...responseData,
      };
      return reorderedData;
    } catch (error) {
      throw new Error("Error fetching boards");
    }
  }
);
// Create a new board
export const createBoard = createAsyncThunk(
  "board/createBoard",
  async (boardData) => {
    try {
      const response = await apiInstance.post(`boards`, boardData);
      // console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      throw new Error("Error creating board");
    }
  }
);

const initialState = {
  boards: [], // Array to store boards
  status: "idle", // Loading status for async actions
  error: null, // Error message if an error occurs
};

const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    // Add any additional synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoards.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBoards.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.boards = action.payload;
      })
      .addCase(fetchBoards.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createBoard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBoard.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.boards.push(action.payload);
      })
      .addCase(createBoard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default boardSlice.reducer;
