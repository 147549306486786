import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar_sa from "./Sidebar_sa";
import './SuperAdminLayout.css';


const SuperAdminLayout = ({ children }) => {
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const isLoggedIn = auth.isLoggedIn;
  const isAdmin = auth.user.is_admin;
  useEffect(() => {
    if (!auth.isLoggedIn || !isAdmin) navigate("/login");
  }, []);

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) navigate("/login");
  }, [isLoggedIn, navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2">
          <Sidebar_sa />
        </div>
        <div className="col-sm-10 mt-5">{children}</div>
      </div>
    </div>
  );
};

export default SuperAdminLayout;
