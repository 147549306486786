import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Sidebar_sa.css"; // Import the CSS file for custom styling
import { Link, useLocation } from "react-router-dom";
import { ArrowLeft, MagicWand, UserGear } from "phosphor-react";

const Sidebar_sa = () => {
  const location = useLocation();

  return (
    <div className="sidebar_sa">
      <Container fluid>
        <Row>
          <Col className="sidebar-col">
            <div className="sidebar-menu">
              <ul>
                <li>
                  <Link
                    to="/admin/stats"
                    className={
                      location.pathname === "/admin/stats" ? "active" : ""
                    }
                  >
                    All Statistics
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/users"
                    className={
                      location.pathname === "/admin/users" ? "active" : ""
                    }
                  >
                    Users
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/tasks"
                    className={
                      location.pathname === "/admin/tasks" ? "active" : ""
                    }
                  >
                    Tasks
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/workspaces"
                    className={
                      location.pathname === "/admin/workspaces" ? "active" : ""
                    }
                  >
                    workspaces
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className={location.pathname === "" ? "active" : ""}
                  >
                    Links
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin/task-permissions"
                    className={
                      location.pathname === "/admin/task-permissions"
                        ? "active"
                        : ""
                    }
                  >
                    task-permissions
                  </Link>
                </li>
                <li>
                  <div className="d-block ">
                    <Link
                      to="/w"
                      className={location.pathname === "/w" ? "active" : ""}
                    >
                      To Main Menu
                      <ArrowLeft />
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Sidebar_sa;
