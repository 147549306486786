import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Toaster } from "react-hot-toast";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import SuperAdminRoute from "./pages/super-admin/SuperAdminLayout";
import { logout } from "./slices/auth";

import EventBus from "./common/EventBus";
import AuthLayout from "./pages/AuthLayout";
import Workspace from "./pages/Workspace";
import Board from "./pages/Board";
import Learning from "./dnd_test/Learning";
import Layout_sa from "./pages/super-admin/Layout_sa";
import Users_sa from "./pages/super-admin/Users_sa";
import Statistics from "./pages/super-admin/Statistics";
import UsersTasks from "./pages/super-admin/UsersTasks";
import WorkspacesCRUD from "./pages/super-admin/WorkspacesCRUD";
import CalendlyComponent from "./pages/CalendlyComponent";
import Space from "./pages/Space";
import Main from "./pages/Main";
import Notifications from "./pages/Notifications";
import LinkList from "./pages/LinkList";
import Chat from "./pages/Chat";
import TaskPermissions from "./pages/super-admin/TaskPermissions";
 
// import Test from "./dnd_test/Test";
const App = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<Learning />} /> */}
        <Route exact path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={
            <AuthLayout>
              <Register />
            </AuthLayout>
          }
        />
        <Route
          path="/w"
          element={
            <Layout>
              <Main />
            </Layout>
          }
        />


 
        <Route
          path="/w/:id"
          element={
            <Layout>
              <Workspace />
            </Layout>
          }
        />
        <Route
          path="/w/:id/:spaceId"
          element={
            <Layout>
              <Space />
            </Layout>
          }
        />

        <Route
          path="/w/:id/:spaceId/board/:boardId"
          element={
            <Layout>
              <Board />
            </Layout>
          }
        />
        {/* Calendly */}
        <Route
          path="/Cale"
          element={
            <Layout>
              <CalendlyComponent />
            </Layout>
          }
        />
        <Route
          path="/notifications"
          element={
            <Layout>
              <Notifications />
            </Layout>
          }
        />
        <Route
          path="/links"
          element={
            <Layout>
              <LinkList />
            </Layout>
          }
        />
            <Route
          path="/chat"
          element={
            <Layout>
             <Chat/>
            </Layout>
          }
        />
        <Route
          path="/admin"
          element={
            <SuperAdminRoute>
              <Layout_sa />
            </SuperAdminRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <SuperAdminRoute>
              <Users_sa />
            </SuperAdminRoute>
          }
        />
        <Route
          path="/admin/stats"
          element={
            <SuperAdminRoute>
              <Statistics />
            </SuperAdminRoute>
          }
        />
        <Route
          path="/admin/tasks"
          element={
            <SuperAdminRoute>
              <UsersTasks />
            </SuperAdminRoute>
          }
        />
        <Route
          path="/admin/workspaces"
          element={
            <SuperAdminRoute>
              <WorkspacesCRUD />
            </SuperAdminRoute>
          }
        />
          <Route
          path="/admin/task-permissions"
          element={
            <SuperAdminRoute>
              <TaskPermissions />
            </SuperAdminRoute>
          }
        />
      </Routes>

      <Toaster />
    </Router>
  );
};

export default App;

/*
notes :
ctrl+shift+J  open network devtool
ctrl+shift+K open console
*/
