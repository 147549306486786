import { API_BASE_URL } from "../config";
import axios from "axios";

const register = (data) => {
  const {name, email, password, c_password} = data
  return axios
    .post(API_BASE_URL + "/register", {
      name,
      email,
      password,
      c_password,
    })
    .then((response) => {
      // alert("ok");
      return {
        status: response.status,
        data: response.data,
      };
    })
    .catch((error) => {
      if (error.response) {
        // The request was made, and the server responded with a status code
        return {
          status: error.response.status,
          data: error.response.data,
        };
      } else if (error.request) {
        // The request was made but no response was received
        return {
          status: 0,
          data: "No response received from the server.",
        };
      } else {
        // Something happened in setting up the request that triggered an Error
        return {
          status: -1,
          data: error.message,
        };
      }
    });
};

const login = (email, password) => {
  // console.log(email + " " + password);
  return axios
    .post(API_BASE_URL + "/login", { email, password })
    .then((response) => {
      // console.log("response: ", response.data.data.user.token);
      if (response.data.data.user.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
      }

      return response.data;
    })
    .catch((error) => {
      // Handle errors here
      console.error("Error during login:", error);
      throw error;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
